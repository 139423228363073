import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./all_css/Detail.css";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { firestore } from "../service/firebaseConfig";
import {
  Box,
  Typography,
  Chip,
  Button,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "../service/firebaseConfig"; // Adjust the import path as needed
import { ExpandMore } from "@mui/icons-material";
import detail_banner from "./images/detail_banner.png";
import diamond_icon from "./images/diamond_icon.png";
import loading_img from "./images/loading_img.png";
import { useLanguage } from "../LanguageContext";
import locales from "./text_assets/locale.js";

interface FAQ {
  question: string;
  answer: string;
}
export interface Contents {
  title: string;
  price: number;
  discounted: string;
  quantity: number;
}

export interface Contents2 {
  title: string;
  price: number;
  key: number;
  event: number;
  description: string;
}

interface Categories {
  category: number;
  title: string;
  description: string;
  contents: Contents2[];
  feature: string[];
  recommendation: string[];
  precaution: string[];
  startingPrice: number;
  packages: number[];
  FAQ: FAQ[];
}

interface DetailProps {
  cartLength: number;
  updateCartLength: () => void;
}
type ImageUrl = {
  name: string;
  url: string;
};
const Detail: React.FC<DetailProps> = ({ cartLength, updateCartLength }) => {
  const [categories, setCategories] = useState<Categories>();
  const [cartAddedPopupOpen, setCartAddedPopupOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState<ImageUrl>();
  const { language, setLanguage } = useLanguage(); // 현재 언어 상태 가져오기

  const { procedureCategory } = useParams<{ procedureCategory: string }>();
  const [cartItems, setCartItems] = useState<Contents2[]>(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });

  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const {
    eventRule1,
    eventRule2,
    addToCart,
    recommendation,
    features,
    precaution,
    oneSessionTrial,
    openPrice,
    originalPrice,
  } = locales[langkey];

  const handleAddToCart = (content: Contents2) => {
    const curCart = localStorage.getItem("cart");
    const currentCart = curCart ? JSON.parse(curCart) : []; // localStorage에서 현재 카트를 가져옵니다.
    const isDuplicate = currentCart.some(
      (item: Contents2) => item.title === content.title
    );
    if (isDuplicate) {
      alert("이미 담긴 상품입니다!");
      return;
    } else {
      const updatedCart = [...currentCart, content]; // 새로운 항목을 카트에 추가합니다.
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setCartItems(updatedCart);
    }
    updateCartLength();

    setCartAddedPopupOpen(true); // Show the popup
  };

  const fetchCategories = async () => {
    try {
      const categoryNationality =
        language === "ko" ? "category" : `category_${language}`;
      const eventNationality =
        language === "ko" ? "event" : `event_${language}`;
      const packageList: Contents2[] = [];
      const eventSnapshot = await getDocs(
        collection(firestore, eventNationality)
      );
      const docRef = doc(
        firestore,
        categoryNationality,
        procedureCategory ? procedureCategory : ""
      );
      const docSnap = await getDoc(docRef);
      setCategories(docSnap.data() as Categories);
      eventSnapshot.forEach((doc) => {
        if (docSnap.data()?.packages.includes(doc.data().key.toString())) {
          packageList.push(doc.data() as Contents2);
        }
      });
      // console.log("PACKage LIST: ", packageList);
      const itemList: Contents2[] = docSnap
        .data()
        ?.contents?.concat(packageList);
      itemList.sort((a, b) => b.event - a.event);
      setCategories({
        category: docSnap.data()?.category,
        title: docSnap.data()?.title,
        description: docSnap.data()?.description,
        feature: docSnap.data()?.feature,
        recommendation: docSnap.data()?.recommendation,
        precaution: docSnap.data()?.precaution,
        startingPrice: docSnap.data()?.startingPrice,
        packages: docSnap.data()?.packages,
        FAQ: docSnap.data()?.FAQ,
        contents: itemList,
      });

      const imageRef = ref(storage, `Details/${procedureCategory}.jpg`);
      getDownloadURL(imageRef)
        .then((url) => {
          setImageUrls({ name: imageRef.name, url });
        })
        .catch((error) => {
          console.error("No image");
        });
    } catch (error) {
      console.error("Error fetching items data:", error);
    }
  };

  // 컴포넌트가 마운트될 때 Firestore에서 데이터 불러오기
  useEffect(() => {
    fetchCategories();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        backgroundColor: "#F6F6F2",
        marginBottom: "20px",
      }}
    >
      {categories ? (
        <>
          <Box position="relative" width="100%" display="inline-block">
            <Box
              component="img"
              src={detail_banner}
              alt="Background"
              sx={{
                width: "100%", // 가로 100%로 설정
                height: "auto", // 비율에 따라 자동으로 높이 조정
                maxHeight: "150px",
                display: "block", // 아래 여백 제거
              }}
            />
            <Box
              position="absolute"
              top="50%"
              left="50%"
              sx={{
                transform: "translate(-50%, -50%)",
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Pretendard",
                  fontWeight: "500",
                  color: "white",
                }}
              >
                {categories.title}
              </Typography>
            </Box>
          </Box>
          <div className="contents-container">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                marginRight: "3px",
              }}
            >
              <Typography
                sx={{
                  marginTop: "2px",
                  fontFamily: "Pretendard",
                  fontWeight: "300",
                  color: "grey",
                  fontSize: "0.8rem",
                  textAlign: "right",
                }}
              >
                {eventRule1}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  fontWeight: "300",
                  color: "grey",
                  fontSize: "0.8rem",
                  textAlign: "right",
                }}
              >
                {eventRule2}
              </Typography>
            </Box>
            {categories.contents.map((content, index) => (
              <Box
                display="flex"
                flexDirection="column"
                gap={1.5}
                key={index}
                sx={{
                  width: "85%",
                  backgroundColor: "white",
                  padding: 2,
                  borderRadius: 3,
                }}
              >
                <Box display="flex" gap={0.5} sx={{ mb: 0.5 }} key={index + 50}>
                  <Chip
                    label={
                      content.event === 1
                        ? "EVENT"
                        : content.event === 2
                        ? oneSessionTrial
                        : content.event === 3
                        ? openPrice
                        : originalPrice
                    }
                    key={index}
                    sx={{
                      height: "150%",
                      backgroundColor:
                        content.event === 1
                          ? "#8c6e52"
                          : content.event === 2
                          ? "#f78b4f"
                          : content.event === 3
                          ? "red"
                          : "grey",
                      color: "white",
                      fontWeight: "semi-bold",
                      fontSize: 11,
                      borderRadius: 0,
                      fontFamily: "Pretendard",
                      "& .MuiChip-label": {
                        paddingLeft: "4px", // 좌측 패딩 설정
                        paddingRight: "4px", // 우측 패딩 설정
                      },
                    }}
                  />
                </Box>
                <Box sx={{ mb: -0.5 }} key={index + 100}>
                  <Typography
                    fontFamily="Pretendard"
                    fontSize="1rem"
                    fontWeight="500"
                    key={index}
                  >
                    {content.title}
                  </Typography>
                  <Typography
                    fontSize="0.85rem"
                    color="textSecondary"
                    fontFamily="Pretendard"
                    key={index + 50}
                  >
                    {content.description ? (
                      content.description.split("@").map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))
                    ) : (
                      <br />
                    )}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  key={index + 150}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-end"
                    key={index + 200}
                  >
                    <Typography
                      fontFamily="Pretendard"
                      fontSize="1rem"
                      fontWeight="500"
                      color="textPrimary"
                      key={index + 200}
                    >
                      {content.price > 0
                        ? language === "ko"
                          ? `${content.price.toLocaleString("en-US")}원`
                          : `₩${content.price.toLocaleString("en-US")}`
                        : "상담 후 결정"}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={"flex-end"}
                    gap={1}
                    alignItems="center"
                    width="40%"
                    height="100%"
                    key={index + 300}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        backgroundColor: "grey",
                        borderRadius: 120,
                        color: "white",
                      }}
                      onClick={() => {
                        handleAddToCart(content);
                      }}
                    >
                      <Typography variant="body2" fontWeight="bold">
                        {addToCart}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
          </div>
          <div className="container">
            {categories.description && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 10px",
                  backgroundColor: "white",
                }}
              >
                <Box sx={{ width: "90%", padding: "10px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#706d68",
                      fontSize: "1rem",
                      marginBottom: "8px",
                      fontFamily: "Pretendard",
                    }}
                  >
                    {categories.title}
                  </Typography>
                  <Divider
                    sx={{ borderColor: "#706d68", marginBottom: "5px" }}
                  />
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <ListItem
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "#F6F6F2",
                      }}
                    >
                      <Typography
                        sx={{ fontFamily: "Pretendard", fontSize: "0.9rem" }}
                      >
                        {categories.description ? (
                          categories.description
                            .split("@")
                            .map((line, index) => (
                              <span key={index}>
                                {line}
                                <br />
                              </span>
                            ))
                        ) : (
                          <br />
                        )}
                      </Typography>
                    </ListItem>
                  </List>
                  {imageUrls && (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                        marginTop: "10px",
                      }}
                      key={imageUrls.name}
                      src={imageUrls.url}
                      alt="Image"
                    />
                  )}
                </Box>
              </Box>
            )}
            {categories.recommendation?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 10px",
                  backgroundColor: "white",
                }}
              >
                <Box sx={{ width: "90%", padding: "10px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#706d68",
                      fontSize: "1rem",
                      marginBottom: "8px",
                      fontFamily: "Pretendard",
                    }}
                  >
                    {recommendation}
                  </Typography>
                  <Divider
                    sx={{ borderColor: "#706d68", marginBottom: "5px" }}
                  />
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    {categories.recommendation.map((item, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "#F6F6F2",
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={diamond_icon}
                            alt="o"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                        </ListItemIcon>
                        <Typography
                          sx={{ fontFamily: "Pretendard", fontSize: "0.9rem" }}
                        >
                          {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            )}
            {categories.feature?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 10px",
                  backgroundColor: "#F6F6F2",
                }}
              >
                <Box sx={{ width: "90%", padding: "10px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#706d68",
                      fontSize: "1rem",
                      marginBottom: "8px",
                      fontFamily: "Pretendard",
                    }}
                  >
                    {features}
                  </Typography>
                  <Divider
                    sx={{ borderColor: "#706d68", marginBottom: "5px" }}
                  />
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    {categories.feature.map((item, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={diamond_icon}
                            alt="o"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                        </ListItemIcon>
                        <Typography
                          sx={{ fontFamily: "Pretendard", fontSize: "0.9rem" }}
                        >
                          {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            )}
            {categories.precaution?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 10px 20px",
                  backgroundColor: "white",
                }}
              >
                <Box sx={{ width: "90%", padding: "40px 20px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#706d68",
                      fontSize: "1rem",
                      marginBottom: "8px",
                      fontFamily: "Pretendard",
                    }}
                  >
                    {precaution}
                  </Typography>
                  <Divider
                    sx={{ borderColor: "#706d68", marginBottom: "5px" }}
                  />
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    {categories.precaution.map((item, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          borderRadius: "10px",
                          backgroundColor: "#F6F6F2",
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={diamond_icon}
                            alt="o"
                            style={{
                              width: "20px",
                              height: "20px",
                              marginRight: "10px",
                            }}
                          />
                        </ListItemIcon>
                        <Typography
                          sx={{ fontFamily: "Pretendard", fontSize: "0.9rem" }}
                        >
                          {item}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            )}
            {categories.FAQ?.length > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  padding: "20px 10px 20px",
                  backgroundColor: "#F6F6F2",
                }}
              >
                <Box sx={{ width: "90%", padding: "40px 20px" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#706d68",
                      fontSize: "1rem",
                      marginBottom: "8px",
                      fontFamily: "Pretendard",
                    }}
                  >
                    FAQ
                  </Typography>
                  <Divider
                    sx={{ borderColor: "#706d68", marginBottom: "5px" }}
                  />
                  <List
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    {categories.FAQ.map((item, index) => (
                      <>
                        <Accordion
                          key={index}
                          disableGutters
                          sx={{
                            backgroundColor: "#F6F6F2",
                            boxShadow: "none",
                            "&:before": {
                              // Accordion 상단의 Divider 제거
                              display: "none",
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography
                              sx={{
                                fontFamily: "Pretendard",
                                fontSize: "0.9rem",
                              }}
                            >
                              {item.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ backgroundColor: "white" }}>
                            <Typography
                              sx={{
                                fontFamily: "Pretendard",
                                fontSize: "0.9rem",
                              }}
                            >
                              {item.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                        <Divider
                          sx={{ borderColor: "#706d68", marginBottom: "5px" }}
                        />
                      </>
                    ))}
                  </List>
                </Box>
              </Box>
            )}
          </div>
        </>
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={loading_img}
            alt="o"
            style={{
              width: "50px",
              height: "50px",
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default Detail;
