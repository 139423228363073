import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import "./all_css/Reservation.css";
import { firestore } from "../service/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import SearchModule from "./function_components/SearchModule";
//MUI import
import {
  Box,
  Typography,
  Grid2,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import reservation_banner from "./images/reservation_banner.png";
import goto_img from "./images/goto_img.png";
import { useLanguage } from "../LanguageContext";
import locales from "./text_assets/locale.js";

interface categorySummary {
  category: number;
  title: string;
  description: string;
  startingPrice: number;
  brief: string;
}
interface ThemeItem {
  title: string;
  categories: categorySummary[];
  key: number;
}

interface ReservationProps {
  setSearchQuery: (query: string) => void;
  setSearchOpen: (open: boolean) => void;
}

const Reservation: React.FC<ReservationProps> = ({
  setSearchQuery,
  setSearchOpen,
}) => {
  const { urlCategory } = useParams<{ urlCategory?: string }>();
  const [themeItem, setThemeItem] = useState<ThemeItem[]>([]);
  const { language, setLanguage } = useLanguage(); // 현재 언어 상태 가져오기
  const [isNaverBrowser, setIsNaverBrowser] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [cat, setCat] = useState<number>(
    searchParams.get("category") !== null
      ? Number(searchParams.get("category"))
      : 1
  );
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const { reservationTitle } = locales[langkey];

  const fetchTheme = async () => {
    const themeNationality = language === "ko" ? "theme" : `theme_${language}`;
    try {
      const querySnapshot = await getDocs(
        collection(firestore, themeNationality)
      );
      const themeData: ThemeItem[] = [];
      querySnapshot.forEach((doc) => {
        themeData.push(doc.data() as ThemeItem);
      });
      themeData.sort((a, b) => a.key - b.key);
      setThemeItem(themeData);
      // console.log(themeData);
    } catch (error) {
      console.error("Error fetching items data:", error);
    }
  };

  // 컴포넌트가 마운트될 때 Firestore에서 데이터 불러오기
  useEffect(() => {
    fetchTheme();
    const userAgent = navigator.userAgent || "";
    if (userAgent.includes("NAVER")) {
      setIsNaverBrowser(true);
    }
  }, []);

  useEffect(() => {
    const ctg = searchParams.get("category");
    setCat(ctg ? Number(ctg) : 1);
  }, [searchParams]);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Box position="relative" width="100%" display="inline-block">
        <Box
          component="img"
          src={reservation_banner}
          alt="Background"
          sx={{
            width: "100%", // 가로 100%로 설정
            height: "auto", // 비율에 따라 자동으로 높이 조정
            maxHeight: "150px",
            display: "block", // 아래 여백 제거
          }}
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Pretendard",
              fontWeight: "500",
              color: "white",
            }}
          >
            {reservationTitle}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "90%",
          left: "50%",
          transform: "translateX(-50%)",
          // height: 200,
          position: "relative",
          top: "20px",
        }}
      >
        <Grid2 container spacing={1.5}>
          {themeItem.map((item, key) => (
            <Grid2 size={4} key={item.key}>
              {/* <Link
              to={`/${language}/reservation/${item.key}`}
              style={{ textDecoration: "none" }}
            > */}
              <Box
                sx={{
                  height: 38,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: item.key === cat ? "#7d7a73" : "white",
                  borderRadius: 2,
                  border: "0.5px solid #e3e5ea",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSearchParams({ category: (1 + key).toString() });
                  setCat(1 + key);
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Pretendard",
                    fontWeight: "500",
                    color: item.key === cat ? "white" : "#45474e",
                    fontSize: !isNaverBrowser ? "0.85rem" : "0.8rem",
                    textAlign: "center",
                  }}
                >
                  {item.title.split("@").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              </Box>
              {/* </Link> */}
            </Grid2>
          ))}
        </Grid2>
      </Box>
      <Box sx={{ padding: "0 20px 20px 20px" }}>
        <SearchModule
          setSearchQuery={setSearchQuery}
          setSearchOpen={setSearchOpen}
        />
      </Box>
      <div>
        <Box
          position="relative"
          top={0}
          left={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
          bgcolor="#f6f6f2"
        >
          <Box width={"full"} sx={{ position: "absolute", top: 20, left: 20 }}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Pretendard",
                fontWeight: "500",
                color: "#202124",
              }}
            >
              {themeItem
                ?.find((item) => item.key === cat)
                ?.title?.replace(/@/g, " ")}
            </Typography>
          </Box>
          <Box
            position="relative"
            sx={{
              width: "100%",
              maxWidth: "100%",
              padding: "10 16px",
              mt: 7,
            }}
          >
            <List>
              {/* {itemCategory?.categories.map((item, index) => ( */}
              {themeItem
                ?.find((item) => item.key === cat)
                ?.categories.map((item, index) => (
                  <Fragment key={index}>
                    <Link
                      to={`/${language}/detail/${item.category}`}
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem
                        sx={{
                          bgcolor: "white",
                          margin: "10px 10px 10px 20px",
                          borderRadius: 1,
                          boxShadow: 1,
                          width: "90%",
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              fontSize="1.1rem"
                              fontWeight="500"
                              color="#202124"
                              fontFamily="Pretendard"
                            >
                              {item.title}
                            </Typography>
                          }
                          secondary={
                            <>
                              <Typography
                                variant="body2"
                                color="#696f6aee"
                                fontFamily="Pretendard"
                                width="90%"
                                height="50px"
                                paddingTop="10px"
                                paddingBottom="5px"
                              >
                                {item.brief ? (
                                  item.brief.split("@").map((line, index) => (
                                    <span key={index}>
                                      {line}
                                      <br />
                                    </span>
                                  ))
                                ) : (
                                  <br />
                                )}
                              </Typography>
                              <Typography
                                fontSize="1rem"
                                fontWeight="500"
                                color="#201e1e"
                                fontFamily="Pretendard"
                              >
                                {language === "ko"
                                  ? `${item.startingPrice.toLocaleString(
                                      "en-US"
                                    )}원부터`
                                  : `₩${item.startingPrice.toLocaleString(
                                      "en-US"
                                    )} ~`}
                              </Typography>
                            </>
                          }
                        />
                        <img
                          alt="Group"
                          src={goto_img}
                          style={{ width: 20, height: 20 }}
                        />
                      </ListItem>
                    </Link>
                    <Divider />
                  </Fragment>
                ))}
            </List>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Reservation;
