import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Divider,
  Modal,
} from "@mui/material";
import Authentication from "./Authentication";
import dayjs, { Dayjs } from "dayjs";
import { Link } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import locales from "./text_assets/locale.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

interface CartItem {
  title: string;
  price: number;
  description: string;
  key: number;
  event: number;
}
interface History {
  reservedItems: CartItem[];
  reservedDate: string;
  reservedTime: string;
  customerNumber: string;
  seqNo: number;
  reservedMemo: string;
}

interface ReservationHistoryProps {
  isVerified: boolean;
  setIsVerified: (query: boolean) => void;
  userName: string;
  setUserName: (query: string) => void;
  phoneNumber: string;
  setPhoneNumber: (query: string) => void;
}

const ReservationHistory: React.FC<ReservationHistoryProps> = ({
  isVerified,
  setIsVerified,
  userName,
  setUserName,
  phoneNumber,
  setPhoneNumber,
}) => {
  const [history, setHistory] = useState<History[]>();

  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const {
    reservationDate,
    year_,
    month_,
    day_,
    time_,
    minute_,
    yourHistory,
    yourName,
    yourPhoneNumberOnly,
    yourRequest,
    yourInquiry,
    yourCancelRequest,
    reservationCheck,
    pleaseInsertNameAndPhone,
    reservationCancelConfirm,
    yesCancelConfirm,
    noCancelConfirm,
  } = locales[langkey];

  //리렌더링 방지를 위한 인증 boolean
  const [historyVerified, setHistoryVerified] = useState<boolean>(() => {
    const storedValue = sessionStorage.getItem("verified");
    return storedValue === "true" ? true : false;
  });
  const [historyName, setHistoryName] = useState<string>(() => {
    const storedValue = sessionStorage.getItem("userName");
    return storedValue ? storedValue : "";
  });

  const [cancelPopupOpen, setCancelPopupOpen] = useState<boolean>(false);
  const [reservedDate, setReservedDate] = useState<string>("");
  const [reservedTime, setReservedTime] = useState<string>("");
  const [tempUserName, setTempUserName] = useState<string>("");
  const [tempPhoneNumber, setTempPhoneNumber] = useState<string>("");
  const [openChangeSlot, setOpenChangeSlot] = useState<number>(100);
  const [emptySlots, setEmptySlots] = useState<number[]>(Array(20).fill(0));
  const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>();
  const [selectedTime, setSelectedTime] = useState<string>("");

  const calcEndTime = (timeString: string) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    if (minutes === 30) {
      const newhour = 1 + hours;
      const endtime = `${newhour}:00`;
      return endtime;
    } else {
      const endtime = `${hours}:30`;
      return endtime;
    }
  };
  const handleDateChange = (newDate: Dayjs | undefined): void => {
    if (newDate) {
      const formattedDate = newDate.format("YYYY-MM-DD"); // dayjs의 format 메서드
      setSelectedDate(newDate);
      console.log(`Formatted Date: ${formattedDate}`);
    }
  };

  const timeSlots = (date?: dayjs.Dayjs): string[] => {
    if (!date) return []; // 날짜가 없을 경우 빈 배열 반환

    const jsDate = date.toDate(); // dayjs 객체를 JavaScript Date로 변환
    const day = jsDate.getDay(); // 요일 계산 (0: 일요일, 6: 토요일)
    const timeSlots: string[] = []; // 결과를 저장할 배열

    if (day < 6) {
      // 평일 (월~금)
      for (let hour = 11; hour <= 20; hour++) {
        timeSlots.push(`${hour}:00`);
        if (hour < 20) {
          timeSlots.push(`${hour}:30`);
        }
      }
    } else {
      // 주말 (토요일)
      for (let hour = 10; hour <= 16; hour++) {
        timeSlots.push(`${hour}:00`);
        if (hour < 16) {
          timeSlots.push(`${hour}:30`);
        }
      }
    }

    return timeSlots;
  };

  // 서버에서 불러온 데이터
  const fetchReservation = async (phoneNum: string): Promise<any> => {
    const today = dayjs().format("YYYY-MM-DD");
    const url = `https://reservation.api.receipt.smartdoctor.systems/reservation-api/v2/reservations?cellPhone=${encodeURIComponent(
      phoneNum
    )}&reservationDateWithinStart=${encodeURIComponent(
      today
    )}&reservationDateWithinEnd=2029-12-31`;
    const token = process.env.REACT_APP_DRCRM_TOKEN;
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const reservedHistory: History[] = data.reservations?.map(
        <
          T extends {
            id: {
              customerNumber: string;
              seqNo: number;
            };
            customerName: string;
            etcMemo: string;
            reservationDate: string;
            reservationStartTime: string;
            reservationMemo: string;
          }
        >(
          item: T,
          index: number
        ) => {
          setUserName(item.customerName);
          const etcMemo = JSON.parse(item.etcMemo) as CartItem[];
          return {
            reservedItems: etcMemo,
            reservedDate: item.reservationDate,
            reservedTime: item.reservationStartTime,
            customerNumber: item.id.customerNumber,
            seqNo: item.id.seqNo,
            reservedMemo: item.reservationMemo,
          };
        }
      );
      console.log("reservedHistory:", reservedHistory);
      setHistory(reservedHistory);
    } catch (error) {
      console.error("Error fetching reservation data:", error);
      throw error; // 에러 발생 시 다시 throw해서 함수 호출자에게 전달
    }
  };

  const disableWeekends = (date: Dayjs | null): boolean => {
    if (!date) return false; // 날짜가 없는 경우 처리

    const jsDate = date.toDate(); // Dayjs 객체를 JavaScript Date로 변환
    const day = jsDate.getDay(); // 요일 계산 (0: 일요일, 6: 토요일)

    const specificDisabledDates: string[] = ["2025-01-28"]; // ISO 형식 문자열로 특정 날짜 설정
    const isSpecificDate = specificDisabledDates.includes(
      date.toISOString().split("T")[0]
    );

    return day === 0 || isSpecificDate; // 일요일(0) 또는 특정 날짜 비활성화
  };

  const cancelRequest = async (
    phoneNum: string,
    reservedDate: string,
    reservedTime: string
  ) => {
    const smsURL = "https://us-central1-pria1-5e67a.cloudfunctions.net/sendSMS";
    try {
      const response = await fetch(smsURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          key: process.env.REACT_APP_ALIGO_APIKEY,
          user_id: process.env.REACT_APP_ALIGO_USERID,
          sender: "0262293499",
          receiver: "01039503499",
          msg: `[프리아의원] ${phoneNum} ${historyName}님 ${reservedDate} ${reservedTime} 예약 취소 요청함.`,
        }),
      });
    } catch (error) {
      console.log("Error sending SMS:", error);
    }
  };

  const changeRequest = async (
    customerNumber: string,
    seqNo: number,
    reservedDate: string,
    reservedTime: string,
    etcMemo: string,
    reservationMemo: string
  ) => {
    const token = process.env.REACT_APP_DRCRM_TOKEN;
    const smsURL = "https://us-central1-pria1-5e67a.cloudfunctions.net/sendSMS";
    const url = `https://reservation.api.receipt.smartdoctor.systems/reservation-api/v2/customers/${customerNumber}/reservations/${seqNo}`;
    const changeRequestData = {
      subjectCode: "14",
      departmentCode: "60",
      chargeDoctorId: "황정원",
      reservationDate: reservedDate,
      reservationStartTime: reservedTime,
      reservationEndTime: calcEndTime(reservedTime),
      labelId: 0,
      consultantId: null,
      etcMemo: etcMemo,
      reservationMemo: reservationMemo,
      prescriptionCodes: [],
      externalReservationFrom: null,
      sendMessage: true,
    };
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(changeRequestData),
      });
    } catch (error) {
      console.log("Error sending SMS:", error);
    }
  };

  function formatDate(dateString: string): string {
    // 날짜 문자열을 Date 객체로 변환
    const date = new Date(dateString);

    // 연도, 월, 일 추출
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함
    const day = date.getDate();

    // 원하는 형식으로 반환
    return `${year}${year_} ${month}${month_} ${day}${day_}`;
  }

  function formatTime(timeString: string): string {
    // 시간 문자열을 ':'로 분리
    const [hour, minute] = timeString.split(":");

    // 원하는 형식으로 반환
    return `${hour}${time_} ${minute}${minute_}`;
  }

  function daysUntil(dateString: string): number {
    // 입력된 날짜를 Date 객체로 변환
    const targetDate = new Date(dateString);

    // 오늘 날짜를 생성
    const today = new Date();

    // 두 날짜 간의 차이를 계산 (밀리초 단위)
    const diffInMilliseconds = targetDate.getTime() - today.getTime();

    // 밀리초를 일 단위로 변환
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return diffInDays;
  }
  useEffect(() => {
    if (historyVerified) {
      const storedPhoneNum = sessionStorage.getItem("phoneNumber");
      fetchReservation(storedPhoneNum ? storedPhoneNum : phoneNumber);
      // console.log(history);
    }
  }, [historyVerified]);
  useEffect(() => {
    const storedName = sessionStorage.getItem("userName");
    setHistoryName(storedName ? storedName : "");
    const storedPhoneNum = sessionStorage.getItem("phoneNumber");
    setPhoneNumber(storedPhoneNum ? storedPhoneNum : "");
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#f6f6f2",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      {cancelPopupOpen && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            padding: 2,
          }}
        >
          <Modal
            open={cancelPopupOpen}
            onClose={() => setCancelPopupOpen(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
                maxWidth: 400,
                bgcolor: "white",
                boxShadow: 24,
                p: 2,
                display: "flex",
                flexDirection: "column",
                borderRadius: 5,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  fontSize: "1rem",
                  marginBottom: 2,
                }}
              >
                {reservationCancelConfirm}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: "0.95rem",
                    width: "100px",
                    height: "fit-content",
                    textAlign: "center",
                    border: "1px solid black",
                    backgroundColor: "#f6f6f2",
                    borderRadius: 1,
                    cursor: "pointer",
                    p: 1,
                  }}
                  onClick={() => {
                    const storedPhoneNum =
                      sessionStorage.getItem("phoneNumber");
                    cancelRequest(
                      storedPhoneNum ? storedPhoneNum : phoneNumber,
                      reservedDate,
                      reservedTime
                    );
                    alert(
                      "예약 취소 요청이 완료되었습니다! 반영에 시간이 소요될 수 있습니다."
                    );
                    setCancelPopupOpen(false);
                  }}
                >
                  {yesCancelConfirm}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: "0.95rem",
                    width: "100px",
                    height: "fit-content",
                    textAlign: "center",
                    border: "1px solid black",
                    backgroundColor: "#f6f6f2",
                    borderRadius: 1,
                    cursor: "pointer",
                    py: 1,
                    px: 3,
                  }}
                  onClick={() => {
                    setCancelPopupOpen(false);
                  }}
                >
                  {noCancelConfirm}
                </Typography>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
      {!historyVerified ? (
        language === "ko" ? (
          <Authentication
            isVerified={historyVerified}
            setIsVerified={setHistoryVerified}
            userName={userName}
            setUserName={setUserName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
          />
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={"center"}
            gap={2}
            sx={{
              width: "100%",
              left: "10%",
              marginTop: "30px",
              bgcolor: "white",
            }}
          >
            <Box display="flex" flexDirection="row" sx={{ width: "85%" }}>
              <input
                type="text"
                value={tempUserName}
                onChange={(e) => setTempUserName(e.target.value)}
                placeholder={yourName}
                style={{ height: 40, width: "100%" }}
              />
            </Box>
            <Box display="flex" flexDirection="row" sx={{ width: "85%" }}>
              <input
                type="text"
                value={tempPhoneNumber}
                onChange={(e) => setTempPhoneNumber(e.target.value)}
                placeholder={yourPhoneNumberOnly}
                style={{ height: 40, width: "100%" }}
              />
            </Box>
            <Box display="flex" flexDirection="row" sx={{ width: "85%" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "fit-content",
                  p: 1,
                  borderRadius: 3,
                  bgcolor: "grey",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (tempUserName !== "" && tempPhoneNumber !== "") {
                    setHistoryName(tempUserName);
                    if (language === "jp") {
                      setPhoneNumber(`+81-${tempPhoneNumber}`);
                    } else if (language === "th") {
                      setPhoneNumber(`+66-${tempPhoneNumber}`);
                    } else {
                      setPhoneNumber(tempPhoneNumber);
                    }
                    sessionStorage.setItem("verified", "true");
                    sessionStorage.setItem("userName", tempUserName);
                    sessionStorage.setItem("phoneNumber", tempPhoneNumber);
                    setHistoryVerified(true);
                    fetchReservation(tempPhoneNumber);
                  } else {
                    alert(pleaseInsertNameAndPhone);
                  }
                }}
              >
                {reservationCheck}
              </Box>
            </Box>
          </Box>
        )
      ) : (
        <Box
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              padding: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontWeight: "500",
                color: "#202124",
                fontSize: "1.1rem",
              }}
            >
              {historyName} {yourHistory}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              position: "relative",
              width: "100%",
            }}
          >
            {history !== undefined && history.length > 0 ? (
              <Box
                sx={{
                  width: "100%",
                  height: "fit-content",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                {history.map((item: History, index: number) => (
                  <Box
                    sx={{
                      position: "relative",
                      width: "85%",
                      backgroundColor: "white",
                      borderRadius: 1,
                      border: "0.5px solid #838383",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Pretendard",
                            fontWeight: "500",
                            color: "#202124",
                            fontSize: "0.95rem",
                          }}
                        >
                          {reservationDate}:{formatDate(item.reservedDate)}
                          {formatTime(item.reservedTime)}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#3f3936",
                            borderRadius: "20px",
                            width: "fit-content",
                            height: "fit-content",
                            px: 1.5,
                            py: 0.7,
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Pretendard",
                              fontWeight: "500",
                              color: "white",
                              fontSize: "0.75rem",
                              textAlign: "center",
                            }}
                          >
                            D-{daysUntil(item.reservedDate)}
                          </Typography>
                        </Box>
                      </Box>
                      <List>
                        <Divider
                          variant="middle"
                          sx={{
                            position: "relative",
                            width: "100%",
                            p: 0,
                            margin: 0,
                          }}
                        />
                        {item.reservedItems.length > 0 &&
                          item.reservedItems.map((i, ind) =>
                            i.title !== "USERMEMO" ? (
                              <ListItem
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  padding: 0.5,
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{
                                        fontFamily: "Pretendard",
                                        fontWeight: "500",
                                        color: "#3f3936",
                                      }}
                                    >
                                      {i.title ? i.title : ""}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      sx={{
                                        fontFamily: "Pretendard",
                                        fontWeight: "500",
                                        color: "#838383",
                                        fontSize: "0.75rem",
                                      }}
                                    >
                                      {i.description ? (
                                        i.description
                                          .split("@")
                                          .map((line, index) => (
                                            <span key={index}>
                                              {line}
                                              <br />
                                            </span>
                                          ))
                                      ) : (
                                        <br />
                                      )}
                                    </Typography>
                                  }
                                />
                                <Typography
                                  sx={{
                                    fontFamily: "Pretendard",
                                    fontWeight: "500",
                                    color: "#3f3936",
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  {i.price.toLocaleString("en-US")}원
                                </Typography>
                                <Divider
                                  variant="middle"
                                  sx={{
                                    position: "relative",
                                    width: "100%",
                                    p: 0,
                                    marginLeft: 0,
                                    marginTop: 2,
                                    marginBottom: 1,
                                  }}
                                />
                              </ListItem>
                            ) : (
                              i.description.length > 0 && (
                                <Typography
                                  sx={{
                                    marginTop: 2,
                                    fontFamily: "Pretendard",
                                    fontWeight: "500",
                                    color: "#3f3936",
                                    fontSize: "0.875rem",
                                  }}
                                >
                                  {yourRequest}: {i.description}
                                </Typography>
                              )
                            )
                          )}
                        <Box
                          position="relative"
                          sx={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "flex-end",
                            left: "17%",
                            mt: 2,
                            width: "100%",
                            gap: 1,
                          }}
                        >
                          {language === "ko" && (
                            <Button
                              variant="contained"
                              sx={{
                                // right: "10%",
                                bottom: "10%",
                                transform: "translateX(-50%)",
                                width: "35%",
                                maxWidth: "300px",
                                height: 35,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                                backgroundColor: "transparent",
                                borderRadius: 3,
                                border: "1px solid grey",
                                wordWrap: "break-word", // 단어가 길 경우 강제로 줄바꿈
                                whiteSpace: "normal", // 텍스트 줄바꿈 허용
                              }}
                              onClick={() => {
                                if (index !== openChangeSlot) {
                                  setOpenChangeSlot(index);
                                } else {
                                  setOpenChangeSlot(100);
                                }
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Pretendard",
                                  fontWeight: "500",
                                  color: "grey",
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                예약 변경하기
                              </Typography>
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            sx={{
                              bottom: "10%",
                              transform: "translateX(-50%)",
                              width: "35%",
                              maxWidth: "300px",
                              height: 35,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 1,
                              backgroundColor: "transparent",
                              borderRadius: 3,
                              border: "1px solid grey",
                              wordWrap: "break-word", // 단어가 길 경우 강제로 줄바꿈
                              whiteSpace: "normal", // 텍스트 줄바꿈 허용
                            }}
                            onClick={() => {
                              setReservedDate(item.reservedDate);
                              setReservedTime(item.reservedTime);
                              setCancelPopupOpen(true);
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Pretendard",
                                fontWeight: "500",
                                color: "grey",
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              {yourCancelRequest}
                            </Typography>
                          </Button>
                        </Box>
                      </List>
                      {openChangeSlot === index && (
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={language}
                          >
                            <DateCalendar
                              value={selectedDate}
                              minDate={dayjs()}
                              onChange={(newDate) => {
                                handleDateChange(newDate);
                                setSelectedTime("");
                              }}
                              shouldDisableDate={disableWeekends}
                              sx={{
                                overflow: "hidden", // overflow 설정
                                width: "100%", // DateCalendar의 전체 폭 조절
                                "& .MuiDayCalendar-weekContainer": {
                                  display: "flex",
                                  justifyContent: "space-between", // 날짜가 컨테이너를 채우도록
                                },
                                "& .MuiPickersDay-root": {
                                  minWidth: "40px", // 날짜 셀의 최소 너비 조절
                                  height: "40px", // 날짜 셀의 높이 조절
                                  margin: "0 2px", // 셀 간의 간격 조절
                                  fontSize: "1rem", // 날짜 텍스트 사이즈 키우기
                                },
                                "& .MuiDayCalendar-header": {
                                  justifyContent: "space-between", // 주 헤더를 좌우로 펼치도록 설정
                                },
                                "& .MuiPickersCalendarHeader-root": {
                                  position: "relative",
                                },
                                "& .MuiPickersArrowSwitcher-root": {
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                },
                                "& .MuiPickersCalendarHeader-labelContainer": {
                                  position: "absolute",
                                  left: "55%",
                                  transform: "translateX(-40%)",
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  width: "30%",
                                },
                              }}
                            />
                          </LocalizationProvider>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 1,
                            }}
                          >
                            <List
                              sx={{
                                left: "50%",
                                transform: "translate(-50%)",
                                display: "grid",
                                gridTemplateColumns: "repeat(4, 1fr)", // 4열로 배치
                                gap: 1, // 아이템 간격 조절
                                padding: 0,
                                width: "100%",
                              }}
                            >
                              {timeSlots(selectedDate).map((time, index) => (
                                <ListItem
                                  key={index}
                                  sx={{
                                    width: "100%",
                                    height: 38,
                                    bgcolor:
                                      emptySlots[index] > 6
                                        ? "#e3e0d7"
                                        : selectedTime === time
                                        ? "#7d7a73"
                                        : "white",
                                    border: "0.5px solid #b3b3b3",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    cursor:
                                      emptySlots[index] < 7
                                        ? "pointer"
                                        : "default",
                                    borderRadius: 3,
                                  }}
                                  onClick={() => {
                                    if (emptySlots[index] < 7) {
                                      setSelectedTime(time);
                                      console.log("Selected Time:", time);
                                    }
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontFamily:
                                        selectedTime === time
                                          ? "Pretendard-SemiBold, Helvetica"
                                          : "Pretendard-Medium, Helvetica",
                                      fontWeight:
                                        selectedTime === time
                                          ? "bold"
                                          : "medium",
                                      color:
                                        selectedTime === time
                                          ? "white"
                                          : emptySlots[index] > 6
                                          ? "grey"
                                          : "#45474e",
                                      fontSize: "0.875rem",
                                      textAlign: "center",
                                    }}
                                  >
                                    {time}
                                  </Typography>
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                          <Box sx={{ marginTop: 2 }}>
                            <Button
                              variant="contained"
                              sx={{
                                left: "50%",
                                bottom: "10%",
                                transform: "translateX(-50%)",
                                width: "90%",
                                height: 40,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,
                                backgroundColor: "transparent",
                                borderRadius: 2,
                                border: "1px solid grey",
                              }}
                              onClick={() => {
                                if (
                                  selectedTime !== "" &&
                                  selectedDate !== undefined
                                ) {
                                  changeRequest(
                                    item.customerNumber,
                                    item.seqNo,
                                    selectedDate?.format("YYYY-MM-DD") || "",
                                    selectedTime,
                                    JSON.stringify(item.reservedItems),
                                    item.reservedMemo
                                  );
                                  alert(
                                    "예약 변경이 완료되었습니다! 페이지를 새로고침해주세요."
                                  );
                                  setOpenChangeSlot(100);
                                } else {
                                  alert("변경하실 날짜와 시간을 선택해주세요.");
                                }
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Pretendard",
                                  fontWeight: 400,
                                  color: "black",
                                  fontSize: "0.875rem",
                                  textAlign: "center",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                변경 요청하기
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
                <Box
                  position="relative"
                  sx={{
                    // bottom: "0%",
                    left: "0%",
                    mt: 2,
                    width: "100%",
                    height: "200px",
                  }}
                >
                  <a
                    href="http://pf.kakao.com/_xhxmizn/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      variant="contained"
                      sx={{
                        left: "50%",
                        // bottom: "10%",
                        transform: "translateX(-50%)",
                        width: "90%",
                        height: 46,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 1,
                        backgroundColor: "#3f3936",
                        borderRadius: 1,
                        border: "1px solid transparent",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Pretendard-SemiBold, Helvetica",
                          fontWeight: "bold",
                          color: "#f6f6f2",
                          fontSize: "0.875rem",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {yourInquiry}
                      </Typography>
                    </Button>
                  </a>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <Link to={`/${language}/reservation`}>
                  <Button
                    variant="outlined"
                    fullWidth
                    sx={{
                      mt: 3,
                      py: 0.5,
                      borderColor: "#827a72",
                      color: "#3f3936",
                      bgcolor: "#e3e0d7",
                      fontFamily: "Pretendard",
                      fontWeight: "500",
                      fontSize: "0.9rem",
                    }}
                  >
                    예약하러가기
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ReservationHistory;
