import React, { useState, useEffect } from "react";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import "./all_css/Reservation.css";
import { firestore } from "../service/firebaseConfig";
import { collection, getDocs, getDoc, doc } from "firebase/firestore";
//MUI import
import { Box, Typography, Grid2, Chip, Button } from "@mui/material";
import event_banner from "./images/event_banner.png";
import { useLanguage } from "../LanguageContext";
import locales from "./text_assets/locale.js";

interface EventItem {
  title: string;
  price: number;
  key: number;
  event: number;
  description: string;
}
interface Contents2 {
  title: string;
  price: number;
  key: number;
  event: number;
  composition: string[];
  description: string;
  quantity: number;
}
interface EventTheme {
  title: string;
  contents: string[];
  key: number;
}

interface EventProps {
  setSearchQuery: (query: string) => void;
  setSearchOpen: (open: boolean) => void;
  updateCartLength: () => void;
  cartLength: number;
}

const Event: React.FC<EventProps> = ({
  setSearchQuery,
  setSearchOpen,
  updateCartLength,
  cartLength,
}) => {
  const { urlCategory } = useParams<{ urlCategory?: string }>();
  const [eventTheme, setEventTheme] = useState<EventTheme[]>([]); // 전체 이벤트 카테고리
  const [eventItem, setEventItem] = useState<EventItem[]>([]); // 선택된 이벤트 카테고리의 아이템
  const [openItems, setOpenItems] = useState<EventItem[]>([]); // 오픈 이벤트 아이템
  const [openSpecialItems, setOpenSpecialItems] = useState<EventItem[]>([]); // 오픈 특가 아이템
  const icategory: string = urlCategory ? urlCategory : "0";
  const [cartItems, setCartItems] = useState<EventItem | Contents2[]>(() => {
    const storedCart = localStorage.getItem("cart");
    return storedCart ? JSON.parse(storedCart) : [];
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [cat, setCat] = useState<number>(
    searchParams ? Number(searchParams.get("category")) : 0
  );
  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  const [isNaverBrowser, setIsNaverBrowser] = useState(false);

  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const {
    event,
    openEventRule1,
    openEventRule2,
    eventRule1,
    eventRule2,
    openSpecialPrice,
    addToCart,
    oneSessionTrial,
    openPrice,
    originalPrice,
    newYearPrice,
    newYearEventName,
    newyearEventRule1,
    afterCounsel,
    referToHomepage,
    februaryEventRule,
  } = locales[langkey];

  const initialFetcth = async () => {
    try {
      const eventThemeData: EventTheme[] = [];
      const eventThemeNationality =
        language === "ko" ? "eventTheme" : `eventTheme_${language}`;
      const eventNationality =
        language === "ko" ? "event" : `event_${language}`;
      const querySnapshot = await getDocs(
        collection(firestore, eventThemeNationality)
      );

      //이벤트 항목 리스트만 먼저 불러오기
      querySnapshot.forEach((docu) => {
        eventThemeData.push(docu.data() as EventTheme);
      });
      eventThemeData.sort((a, b) => a.key - b.key);
      await setEventTheme(eventThemeData);

      //오픈특가 불러오기
      const openSpecialItemData = await Promise.all(
        eventThemeData[0].contents.map(async (item: string) => {
          const docRef = doc(firestore, eventNationality, item);
          const docSnap = await getDoc(docRef);
          return docSnap.data() as EventItem; // 각 문서 데이터를 반환
        })
      );
      setOpenSpecialItems(openSpecialItemData);

      //1회 체험가 불러오기
      const categoryCollection = collection(
        firestore,
        language === "ko" ? "category" : `category_${language}`
      );
      const querySnapshot0 = await getDocs(categoryCollection);
      const opens: EventItem[] = [];
      querySnapshot0.forEach((doc) => {
        const data = doc.data();
        if (data.contents && Array.isArray(data.contents)) {
          const filteredContents = data.contents.filter(
            (item: EventItem) => item.event === 2
          );
          opens.push(...filteredContents);
        }
      });
      opens.sort((a, b) => a.price - b.price);
      setOpenItems(opens);
    } catch (error) {
      console.error("Error fetching initial:", error);
    }
  };

  const fetchOne = async (index: number) => {
    const eventNationality = language === "ko" ? "event" : `event_${language}`;
    if (index !== 0) {
      const eventThemeItem = eventTheme?.find((item) => item.key === index);
      if (eventThemeItem) {
        const eventItemData = await Promise.all(
          eventThemeItem.contents.map(async (item: string) => {
            const docRef = doc(firestore, eventNationality, item);
            const docSnap = await getDoc(docRef);
            return docSnap.data() as EventItem; // 각 문서 데이터를 반환
          })
        );
        setEventItem(eventItemData); // 상태 업데이트
      }
    } else return;
  };
  const handleAddToCart = (content: EventItem) => {
    const curCart = localStorage.getItem("cart");
    const currentCart = curCart ? JSON.parse(curCart) : []; // localStorage에서 현재 카트를 가져옵니다.
    const isDuplicate = currentCart.some(
      (item: EventItem) => item.title === content.title
    );
    if (isDuplicate) {
      alert("이미 담긴 상품입니다.");
      return;
    } else {
      const updatedCart = [...currentCart, content]; // 새로운 항목을 카트에 추가합니다.
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      setCartItems(updatedCart);
    }
    updateCartLength();
  };

  // 컴포넌트가 마운트될 때 Firestore에서 데이터 불러오기
  useEffect(() => {
    initialFetcth();
    const userAgent = navigator.userAgent || "";
    if (userAgent.includes("NAVER")) {
      setIsNaverBrowser(true);
    }
  }, []);

  useEffect(() => {
    const cat_str = searchParams.get("category");
    setCat(cat_str ? Number(cat_str) : -2);
    if (eventTheme.length > 0 && cat !== 0) {
      console.log(cat);
      fetchOne(cat);
    }
    // console.log(eventItem);
  }, [eventTheme, searchParams]);

  return (
    <div style={{ position: "relative", width: "100%", marginBottom: "40px" }}>
      <Box position="relative" width="100%" display="inline-block">
        <Box
          component="img"
          src={event_banner}
          alt="Background"
          sx={{
            width: "100%", // 가로 100%로 설정
            height: "auto", // 비율에 따라 자동으로 높이 조정
            maxHeight: "150px",
            display: "block", // 아래 여백 제거
          }}
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: "Pretendard",
              fontWeight: "500",
              color: "white",
            }}
          >
            {event}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "90%",
          left: "50%",
          transform: "translateX(-50%)",
          position: "relative",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <Grid2 container spacing={1.5}>
          {eventTheme.map((item, key) => (
            <Grid2 size={4} key={key}>
              <Box
                sx={{
                  height: 38,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: item.key === cat ? "#7d7a73" : "white",
                  borderRadius: 2,
                  border: "0.5px solid #e3e5ea",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCat(item.key);
                  setSearchParams({ category: item.key.toString() });
                  if (item.key > 0) {
                    fetchOne(item.key);
                  }
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Pretendard",
                    fontWeight: "500",
                    color: item.key === cat ? "white" : "#45474e",
                    fontSize: !isNaverBrowser ? "0.85rem" : "0.8rem",
                    textAlign: "center",
                    whiteSpace: "pre-line",
                  }}
                >
                  {item.title.split("@").map((line, index) => (
                    <span key={index}>
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Box>
      <div>
        <Box
          position="relative"
          top={0}
          left={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
          bgcolor="#f6f6f2"
        >
          <Box
            sx={{ position: "relative", top: 20, left: 20, marginBottom: 2 }}
          >
            <Typography
              sx={{
                fontSize: "1.25rem",
                fontFamily: "Pretendard",
                fontWeight: "500",
                color: "#202124",
              }}
            >
              {cat === -2
                ? newYearEventName
                : eventTheme
                    .find((item) => item.key === cat)
                    ?.title.replace(/@/g, " ")}
            </Typography>
          </Box>
          {/* {cat === 0 && ( */}
          <Box
            position="relative"
            sx={{
              width: "100%",
              height: "fit-content",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginTop: "5px",
            }}
          >
            <Typography
              sx={{
                marginTop: "5px",
                fontFamily: "Pretendard",
                fontWeight: "300",
                color: "grey",
                fontSize: "0.8rem",
                textAlign: "right",
              }}
            >
              {cat >= 0
                ? eventRule1
                : cat === -1
                ? openEventRule1
                : cat === -2
                ? newyearEventRule1
                : februaryEventRule}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Pretendard",
                fontWeight: "300",
                color: "grey",
                fontSize: "0.8rem",
                textAlign: "right",
                marginBottom: "5px",
              }}
            >
              {cat >= 0 ? eventRule2 : cat === -1 ? openEventRule2 : ""}
            </Typography>
          </Box>
          {/* )} */}
          <Box
            position="relative"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {cat !== 0
              ? /* {eventTheme.find((item) => item.key === cat)?.eventItems.map((content, index) => ( */
                (cat > -3 ? eventItem : openSpecialItems).map(
                  (content, index) => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      key={index}
                      sx={{
                        width: "85%",
                        height: "auto",
                        backgroundColor: "white",
                        padding: 2,
                        borderRadius: 3,
                        marginBottom: 2,
                      }}
                    >
                      <Box display="flex" sx={{ mb: 0.5 }} key={index + 50}>
                        <Chip
                          label={
                            content.event === 1
                              ? "EVENT"
                              : content.event === 2
                              ? oneSessionTrial
                              : content.event === 3
                              ? openPrice
                              : content.event === 4
                              ? newYearPrice
                              : originalPrice
                          }
                          key={index}
                          sx={{
                            height: "150%",
                            backgroundColor:
                              content.event === 1
                                ? "#8c6e52"
                                : content.event === 2
                                ? "#f78b4f"
                                : content.event === 3
                                ? "red"
                                : content.event === 4
                                ? "green"
                                : "grey",
                            color: "white",
                            fontWeight: "semi-bold",
                            fontSize: 11,
                            borderRadius: 0,
                            fontFamily: "Pretendard",
                            "& .MuiChip-label": {
                              paddingLeft: "4px", // 좌측 패딩 설정
                              paddingRight: "4px", // 우측 패딩 설정
                            },
                          }}
                        />
                      </Box>

                      <Box key={index + 100}>
                        <Typography
                          fontSize="1rem"
                          fontWeight="500"
                          fontFamily="Pretendard"
                          key={index}
                        >
                          {content.title}
                        </Typography>
                        <Typography
                          fontSize="0.8rem"
                          color="textSecondary"
                          fontFamily="Pretendard"
                          key={index + 50}
                        >
                          {content.description ? (
                            content.description
                              .split("@")
                              .map((line, index) => (
                                <span key={index}>
                                  {line}
                                  <br />
                                </span>
                              ))
                          ) : (
                            <br />
                          )}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        key={index + 150}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          justifyContent="flex-end"
                          key={index + 200}
                        >
                          <Typography
                            fontFamily="Pretendard"
                            fontSize="1rem"
                            fontWeight="500"
                            color="textPrimary"
                            key={index + 200}
                          >
                            {content.price > 0
                              ? language === "ko"
                                ? `${content.price.toLocaleString("en-US")}원`
                                : `₩${content.price.toLocaleString("en-US")}`
                              : content.price === 0
                              ? afterCounsel
                              : referToHomepage}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent={"center"}
                          gap={1}
                          alignItems="center"
                          width="40%"
                          key={index + 300}
                        >
                          <Button
                            sx={{
                              width: "100%",
                              height: "80%",
                              backgroundColor: "grey",
                              borderRadius: 120,
                              color: "white",
                            }}
                            onClick={() => handleAddToCart(content)}
                          >
                            <Typography variant="body2" fontWeight="bold">
                              {addToCart}
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  )
                )
              : openItems.map((content, index) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    key={index}
                    sx={{
                      width: "85%",
                      height: "auto",
                      backgroundColor: "white",
                      padding: 2,
                      borderRadius: 3,
                      marginBottom: 2,
                    }}
                  >
                    <Box display="flex" sx={{ mb: 0.5 }} key={index + 50}>
                      <Chip
                        label={
                          content.event === 1
                            ? "EVENT"
                            : content.event === 2
                            ? oneSessionTrial
                            : content.event === 3
                            ? openPrice
                            : content.event === 4
                            ? newYearPrice
                            : originalPrice
                        }
                        key={index}
                        sx={{
                          height: "150%",
                          backgroundColor:
                            content.event === 1
                              ? "#8c6e52"
                              : content.event === 2
                              ? "#f78b4f"
                              : content.event === 3
                              ? "red"
                              : content.event === 4
                              ? "green"
                              : "grey",
                          color: "white",
                          fontWeight: "semi-bold",
                          fontSize: 11,
                          borderRadius: 0,
                          fontFamily: "Pretendard",
                          "& .MuiChip-label": {
                            paddingLeft: "4px", // 좌측 패딩 설정
                            paddingRight: "4px", // 우측 패딩 설정
                          },
                        }}
                      />
                    </Box>
                    <Box key={index + 100}>
                      <Typography
                        fontFamily="Pretendard"
                        fontSize="1rem"
                        fontWeight="500"
                        color="textPrimary"
                        key={index}
                      >
                        {content.title}
                      </Typography>
                      <Typography
                        fontSize="0.8rem"
                        color="textSecondary"
                        fontFamily="Pretendard"
                        key={index + 50}
                      >
                        {content.description ? (
                          content.description.split("@").map((line, index) => (
                            <span key={index}>
                              {line}
                              <br />
                            </span>
                          ))
                        ) : (
                          <br />
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      key={index + 150}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        justifyContent="flex-end"
                        key={index + 200}
                      >
                        <Typography
                          fontFamily="Pretendard"
                          fontSize="1rem"
                          fontWeight="500"
                          color="textPrimary"
                          key={index + 200}
                        >
                          {content.price > 0
                            ? language === "ko"
                              ? `${content.price.toLocaleString("en-US")}원`
                              : `₩${content.price.toLocaleString("en-US")}`
                            : afterCounsel}
                        </Typography>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent={"center"}
                        gap={1}
                        alignItems="center"
                        width="40%"
                        key={index + 300}
                      >
                        <Button
                          sx={{
                            width: "100%",
                            height: "80%",
                            backgroundColor: "grey",
                            borderRadius: 120,
                            color: "white",
                          }}
                          onClick={() => handleAddToCart(content)}
                        >
                          <Typography variant="body2" fontWeight="bold">
                            {addToCart}
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                ))}
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default Event;
