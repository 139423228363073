import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../service/firebaseConfig";
import {
  Box,
  Typography,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import caution_background from "./images/caution_background.png";
import white_text_logo_no_background from "./images/white_text_logo_no_background.png";
import { useLanguage } from "../LanguageContext";
import locales from "./text_assets/locale.js";

interface CautionType {
  id: string;
  title: string;
  contents: string[];
}
interface CautionProps {
  setTabOpen: (open: boolean) => void;
}

const Caution: React.FC<CautionProps> = ({ setTabOpen }) => {
  const [cautionList, setCautionList] = useState<CautionType[]>([]);
  const [expanded, setExpanded] = useState(false);
  const { language, setLanguage } = useLanguage(); // 현재 언어 상태 가져오기

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const { precaution } = locales[langkey];
  const getCautionList = async () => {
    const querySnapshot =
      language === "ko"
        ? await getDocs(collection(firestore, "caution"))
        : await getDocs(collection(firestore, `caution_${language}`));

    const list: CautionType[] = querySnapshot.docs.map((doc) => {
      const data = doc.data() as CautionType;
      return {
        id: doc.id, // 문서 이름 (Doc ID)
        title: data.title,
        contents: data.contents,
      };
    });
    list.sort((a, b) => Number(a.id) - Number(b.id));
    setCautionList(list);
  };

  useEffect(() => {
    setTabOpen(false);
    getCautionList();
  }, []);

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <Box position="relative" width="100%" display="inline-block">
        <Box
          position="absolute"
          component="img"
          src={caution_background}
          alt="Background"
          sx={{
            width: "100%", // 가로 100%로 설정
            height: "auto", // 비율에 따라 자동으로 높이 조정
            display: "block", // 아래 여백 제거
          }}
        />
        <Box
          position="relative"
          sx={{
            color: "white",
            fontWeight: "bold",
            height: "230px",
          }}
        >
          <img
            src={white_text_logo_no_background}
            style={{
              position: "relative",
              width: "36%",
              maxWidth: "180px",
              height: "auto",
              objectFit: "cover",
              left: "6%",
              top: 30,
            }}
          />
          <Typography
            sx={{
              position: "relative",
              top: 30,
              left: "6%",
              fontFamily: "Pretendard",
              fontWeight: "300",
              color: "white",
              fontSize: "1.6rem",
              "@media (max-width: 750px)": {
                fontSize: "1.6rem", // 모바일 모드 글자 크기
              },
              marginBottom: 3,
            }}
          >
            {precaution}
          </Typography>
        </Box>
      </Box>
      <List>
        {cautionList.map((item, index) => (
          <Accordion
            key={index}
            disableGutters
            sx={{
              backgroundColor: "#F6F6F2",
              boxShadow: "none",
              "&:before": {
                // Accordion 상단의 Divider 제거
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontFamily: "Pretendard",
                  fontSize: "1rem",
                  fontWeight: "500",
                }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>

            <AccordionDetails sx={{ backgroundColor: "white" }}>
              {item.contents.map((item, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: "0.95rem",
                    fontWeight: "300",
                    mb: 1,
                  }}
                >
                  • {item}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Box>
  );
};

export default Caution;
