import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
  useNavigate,
  redirect,
} from "react-router-dom";
import { LanguageProvider, useLanguage } from "./LanguageContext";
import Home from "./components/Home";
import Introduction from "./components/Introduction";
import Reservation from "./components/Reservation";
import SideMenu from "./components/SideMenu";
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";
import Event from "./components/Event";
import Caution from "./components/Caution";
import "./App.css";
import Detail, { Contents2 } from "./components/Detail";
import Cart from "./components/Cart";
import { initializeApp } from "firebase/app";
import { firebaseConfig, storage } from "./service/firebaseConfig";
import { ref, getDownloadURL } from "firebase/storage";
import WholeSearch from "./components/WholeSearch";
import ReservationHistory from "./components/ReservationHistory";
import PriaLogo_black from "./components/images/PriaLogo_black.png";
import SearchModule from "./components/function_components/SearchModule";
import CouncelingMethodPopup from "./components/CouncelingMethodPopup";
import { getParsedLocalStorageItem } from "./service/localstorageService";
import { Box, Modal, Typography, Button } from "@mui/material";
import Policy from "./components/Policy";
import Marketingpolicy from "./components/Marketingpolicy";
import Upload from "./service/upload";
import kakao_qrcode from "./components/images/kakao_qrcode.png";
import insta_qrcode from "./components/images/insta_qrcode.png";
import insta_qrcode_jp from "./components/images/insta_qrcode_jp.jpg";
import insta_qrcode_th from "./components/images/insta_qrcode_th.jpg";
import kakaotalkIcon from "./components/images/kakaotalkIcon.jpg";
import instaIcon from "./components/images/instaIcon.jpg";
import CartAddedPopup from "./components/CartAddedPopup";
import lineIcon from "./components/images/lineIcon.jpg";
import line_qrcode from "./components/images/line_qrcode.png";
import line_qrcode_th from "./components/images/line_qrcode_th.jpg";

// Initialize Firebase
initializeApp(firebaseConfig);

type ImageUrl = {
  name: string;
  url: string;
};

function App() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tabOpen, setTabOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1250);
  const [isCouncelPopupOpen, setIsCouncelPopupOpen] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [cartLength, setCartLength] = useState<number>(0);
  const [userName, setUserName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [lastParams, setLastParams] = useState<string>("/");
  const location = useLocation();
  const { language, setLanguage } = useLanguage();
  const languages = ["/ko", "/jp", "/cn", "/th", "/en"];

  const [mainPopupOpen, setMainPopupOpen] = useState(false);
  const [popupUrl, setPopupUrl] = useState<ImageUrl>();

  const navigate = useNavigate();

  const updateCartLength = () => {
    const leng = getParsedLocalStorageItem<Contents2[]>("cart")?.length;
    setCartLength(leng || 0);
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };
  const checkScreenSize = (): void => {
    setIsMobile(window.innerWidth <= 1250);
  };
  const redirectBasedOnLocation = async () => {
    const currentPath = location.pathname;
    if (currentPath !== "/" && currentPath !== "") {
      return;
    } else {
      try {
        // 리디렉션이 필요 없는 경로를 명시
        const excludedPaths = ["/jp", "/en", "/cn", "/ko", "/th"];

        // 사용자가 명시적으로 해당 경로로 이동했으면 리디렉션 하지 않음
        // if (excludedPaths.includes(currentPath)) {

        // ipinfo API 호출 (API 토큰 입력 필요)

        const response = await fetch(
          "https://ipinfo.io/json?token=9fee49490d5de4"
        );
        if (!response.ok) {
          throw new Error(`Error fetching location: ${response.statusText}`);
        }

        const data: { country?: string } = await response.json();

        // 국가 코드 가져오기
        const country = data.country || "KR"; // 기본값: 한국(KR)
        console.log("Country:", data.country);
        // 국가별 리디렉션 경로
        const redirectPaths: Record<string, string> = {
          KR: "/ko",
          JP: "/jp",
          CN: "/cn",
          US: "/en",
          TH: "/th",
          default: "/en",
        };

        // 적합한 리디렉션 경로로 이동
        const redirectPath = redirectPaths[country] || redirectPaths.default;
        setLanguage(redirectPath.slice(1)); // 언어 상태 업데이트
        if (window.location.pathname !== redirectPath) {
          window.location.href = redirectPath; // 리디렉션
        }
        console.log("Redirected to:", redirectPath);
      } catch (error) {
        console.error("Failed to fetch location:", error);
        // 오류 시 기본 경로로 이동
        window.location.href = "/en";
      }
    }
  };

  const findLanguage = async (): Promise<string> => {
    try {
      const response = await fetch(
        "https://ipinfo.io/json?token=9fee49490d5de4"
      );
      if (!response.ok) {
        throw new Error(`Error fetching location: ${response.statusText}`);
      }

      const data: { country?: string } = await response.json();
      const country = data.country || "KR"; // 기본값: 한국(KR)

      const redirectPaths: Record<string, string> = {
        KR: "ko",
        JP: "jp",
        CN: "cn",
        US: "en",
        TH: "th",
        default: "en",
      };
      return redirectPaths[country] || redirectPaths.default;
    } catch (error) {
      console.error("Error fetching location:", error);
      return "en"; // 기본값: 영어(en)
    }
  };

  const fetchPopup = async () => {
    try {
      const initialPathParts = location.pathname.split("/");
      const langFromURL = initialPathParts[1]; // 첫 번째 파라미터
      if (langFromURL.length > 0) {
        const imageRef = ref(storage, `Popups/${langFromURL}.jpg`);
        console.log("langFromAPI:", langFromURL);

        const popupHiddenUntil = localStorage.getItem("popupHiddenUntil");
        const now = new Date().getTime();
        getDownloadURL(imageRef)
          .then((url) => {
            setPopupUrl({ name: imageRef.name, url });
            if (
              (!popupHiddenUntil || now > Number(popupHiddenUntil)) &&
              languages.includes(location.pathname)
            ) {
              setMainPopupOpen(true);
            }
          })
          .catch((error) => {
            setMainPopupOpen(false);
            return;
          });
      } else {
        const langFromAPI = await findLanguage();
        const imageRef = ref(storage, `Popups/${langFromAPI}.jpg`);
        console.log("langFromAPI:", langFromAPI);

        const popupHiddenUntil = localStorage.getItem("popupHiddenUntil");
        const now = new Date().getTime();
        getDownloadURL(imageRef)
          .then((url) => {
            setPopupUrl({ name: imageRef.name, url });
            if (
              (!popupHiddenUntil || now > Number(popupHiddenUntil)) &&
              languages.includes(location.pathname)
            ) {
              setMainPopupOpen(true);
            }
          })
          .catch((error) => {
            setMainPopupOpen(false);
            return;
          });
      }
    } catch (error) {
      console.error("Error fetching items data:", error);
    }
  };

  const handleDontShowForAWeek = () => {
    const oneWeekLater = new Date().getTime() + 7 * 24 * 60 * 60 * 1000; // 1주일 후의 타임스탬프
    // const oneWeekLater = new Date().getTime() + 1000; // 10초 뒤의 타임스탬프

    localStorage.setItem("popupHiddenUntil", String(oneWeekLater)); // localStorage에 저장
    setMainPopupOpen(false); // 팝업 닫기
  };

  const LanguageWrapper: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const { setLanguage } = useLanguage();
    const { lang } = useParams(); // URL에서 언어 코드 추출

    useEffect(() => {
      if (lang) {
        setLanguage(lang); // 언어 상태 업데이트
      }
    }, [lang]);

    return <>{children}</>;
  };

  // Effect to add and clean up the resize event listener
  useEffect(() => {
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, [window.innerWidth]);

  useEffect(() => {
    redirectBasedOnLocation();
    fetchPopup();
    // console.log("after popup fetch language:", language);
  }, []);

  useEffect(() => {
    // Firebase의 onAuthStateChanged를 사용하여 로그인 상태 체크
    setTabOpen(true); // 탭 열림

    // vh 단위설정
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    // 초기 설정 및 리사이즈 이벤트 추가
    setVh();
    window.addEventListener("resize", () => {});
    // 컴포넌트가 언마운트될 때 이벤트 리스너 해제
    return () => {
      // unsubscribe();
      window.removeEventListener("resize", setVh);
    };
  }, []);

  useEffect(() => {
    if (menuOpen || isCouncelPopupOpen || languageMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // 컴포넌트가 언마운트될 때 overflow를 auto로 설정
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuOpen, isCouncelPopupOpen, languageMenuOpen]);

  useEffect(() => {
    // 경로가 "/search"를 포함하는 경우를 제외하고 직전 경로를 저장
    if (!location.pathname.includes("/search")) {
      setLastParams(location.pathname);
    }
  }, [location.pathname]);

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          maxWidth: "750px",
          maxHeight: "100vh",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      ></div>
    ); // 인증 상태를 확인하는 동안 로딩 표시
  } else
    return (
      // 전체 폭 조정하려면 Home.css, Header.css의 max-width 수정
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          minHeight: "100vh",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        {mainPopupOpen && (
          <div
            style={{
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              zIndex: 300,
            }}
          >
            {/* 팝업 모달 */}
            <Modal
              open={mainPopupOpen}
              onClose={() => setMainPopupOpen(false)}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  maxWidth: 400,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 1,
                }}
              >
                <img
                  src={popupUrl?.url}
                  alt="PRIA CLINIC"
                  style={{ width: "100%", height: "auto" }}
                />
                {/* 버튼 영역 */}
                <Box
                  sx={{
                    mt: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "65%",
                      backgroundColor: "white", // 버튼 배경색
                      color: "grey",
                    }}
                    onClick={handleDontShowForAWeek}
                  >
                    1주동안 다시 보지 않기
                  </Button>
                  <Button
                    sx={{
                      width: "25%",
                      backgroundColor: "grey", // 버튼 배경색
                      color: "#FFFFFF",
                    }}
                    onClick={() => setMainPopupOpen(false)}
                  >
                    닫기
                  </Button>
                </Box>
              </Box>
            </Modal>
          </div>
        )}
        <LanguageProvider>
          {window.innerWidth > 1250 && (
            <div
              id="side"
              style={{
                position: "relative",
                width: "400px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  position: "fixed",
                  bottom: "5%",
                  width: "400px",
                }}
              >
                <img
                  src={PriaLogo_black}
                  alt="Pria"
                  style={{
                    width: "40%",
                    height: "auto",
                  }}
                />
                <div
                  style={{
                    width: "94%",
                    justifyContent: "center",
                    // border: "2px solid #e3e5ea",
                    padding: "12px",
                    paddingTop: "24px",
                    marginBottom: "24px",
                  }}
                >
                  <Typography
                    fontFamily="Pretendard"
                    color="textPrimary"
                    fontWeight="500"
                    fontSize="1.25rem"
                    sx={{ textAlign: "left" }}
                  >
                    {location.pathname.startsWith("/ko")
                      ? "궁금한 시술을 검색해보세요"
                      : location.pathname.startsWith("/jp")
                      ? "気になる施術を検索"
                      : "Find the procedure you're looking for."}
                  </Typography>
                  <LanguageWrapper>
                    <SearchModule
                      setSearchQuery={setSearchQuery}
                      setSearchOpen={setSearchOpen}
                    />
                  </LanguageWrapper>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "20px",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      width: "40%",
                      height: "fit-content",
                      backgroundColor: "white",
                      borderRadius: 10,
                      padding: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <img
                      src={
                        location.pathname.startsWith("/ko")
                          ? kakaotalkIcon
                          : lineIcon
                      }
                      alt="kakaoIcon"
                      style={{ width: "50px", cursor: "pointer" }}
                      onClick={
                        () =>
                          location.pathname.startsWith("/ko")
                            ? window.open(
                                "http://pf.kakao.com/_xhxmizn/",
                                "_blank"
                              )
                            : location.pathname.startsWith("/jp")
                            ? window.open("https://lin.ee/aHdHliU", "_blank") //jp
                            : window.open("https://lin.ee/t5uOVrC", "_blank") //th
                      }
                    />
                    <img
                      src={
                        location.pathname.startsWith("/ko")
                          ? kakao_qrcode
                          : location.pathname.startsWith("/jp")
                          ? line_qrcode
                          : line_qrcode_th
                      }
                      alt="snsQR"
                      style={{
                        width: "130px",
                        aspectRatio: 1,
                        marginBottom: "20px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      width: "40%",
                      height: "fit-content",
                      backgroundColor: "white",
                      borderRadius: 10,
                      padding: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "20px",
                    }}
                  >
                    <img
                      src={instaIcon}
                      alt="insta"
                      style={{ width: "50px", cursor: "pointer" }}
                      onClick={
                        () =>
                          location.pathname.startsWith("/ko")
                            ? window.open(
                                "https://www.instagram.com/pria_clinic?igsh=N3lvem8xeTI4NzBz",
                                "_blank"
                              )
                            : location.pathname.startsWith("/jp")
                            ? window.open(
                                "https://www.instagram.com/pria_clinic_jp?igsh=bHdsMGc0Z3Y1Yno5",
                                "_blank"
                              ) //jp
                            : window.open(
                                "https://www.instagram.com/priaclinic.th?igsh=MTJ2ajVhNmJxZWFwZw==",
                                "_blank"
                              ) //th
                      }
                    />
                    <img
                      src={
                        location.pathname.startsWith("/ko")
                          ? insta_qrcode
                          : location.pathname.startsWith("/jp")
                          ? insta_qrcode_jp
                          : insta_qrcode_th
                      }
                      alt="instaQRcode"
                      style={{
                        width: "130px",
                        aspectRatio: 1,
                        marginBottom: "20px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            id="main"
            style={{
              // position: "relative",
              width: "100%",
              maxWidth: "750px",
              backgroundColor: "white",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {tabOpen && (
              <div
                id="headers"
                style={{
                  position: "relative",
                  width: "100%",
                  height: "90px",
                }}
              >
                <LanguageWrapper>
                  <Header2
                    menuOpen={menuOpen}
                    setMenuOpen={setMenuOpen}
                    searchOpen={searchOpen}
                    setSearchOpen={setSearchOpen}
                    cartLength={cartLength}
                    updateCartLength={updateCartLength}
                    setLastParams={setLastParams}
                    languageMenuOpen={languageMenuOpen}
                    setLanguageMenuOpen={setLanguageMenuOpen}
                  />
                </LanguageWrapper>
                <LanguageWrapper>
                  <Header3 lastParams={lastParams} />
                </LanguageWrapper>
              </div>
            )}

            <div id="content" style={{ width: "100%", minHeight: "100vh" }}>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  zIndex: 200,
                  width: "100%",
                  height: "fit-content",
                }}
              >
                {menuOpen && (
                  <SideMenu
                    isOpen={menuOpen}
                    onClose={toggleMenu}
                    setIsCouncelPopupOpen={setIsCouncelPopupOpen}
                  />
                )}
              </div>
              <div
                style={{
                  position: "fixed",
                  zIndex: 200,
                  width: "100%",
                  maxWidth: "750px",
                  height: "fit-content",
                  bottom: 0,
                }}
              >
                {(location.pathname.includes("/detail") ||
                  location.pathname.includes("/event")) &&
                  cartLength > 0 && <CartAddedPopup cartLength={cartLength} />}
              </div>
              <Routes>
                <Route
                  path="/:lang"
                  element={
                    <LanguageWrapper>
                      <Home
                        setSearchQuery={setSearchQuery}
                        setSearchOpen={setSearchOpen}
                        setIsCouncelPopupOpen={setIsCouncelPopupOpen}
                      />
                    </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/introduction"
                  element={
                    <LanguageWrapper>
                      <Introduction />
                    </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/reservation"
                  element={
                    // <LanguageWrapper>
                    <Reservation
                      setSearchQuery={setSearchQuery}
                      setSearchOpen={setSearchOpen}
                    />
                    // </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/event"
                  element={
                    // <LanguageWrapper>
                    <Event
                      setSearchQuery={setSearchQuery}
                      setSearchOpen={setSearchOpen}
                      updateCartLength={updateCartLength}
                      cartLength={cartLength}
                    />
                    // </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/event/:urlCategory"
                  element={
                    <LanguageWrapper>
                      <Event
                        setSearchQuery={setSearchQuery}
                        setSearchOpen={setSearchOpen}
                        updateCartLength={updateCartLength}
                        cartLength={cartLength}
                      />
                    </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/cart"
                  element={
                    <LanguageWrapper>
                      <Cart
                        isVerified={isVerified}
                        setIsVerified={setIsVerified}
                        cartLength={cartLength}
                        updateCartLength={updateCartLength}
                        userName={userName}
                        setUserName={setUserName}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                      />
                    </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/cart/:urlCategory"
                  element={
                    <LanguageWrapper>
                      <Cart
                        isVerified={isVerified}
                        setIsVerified={setIsVerified}
                        cartLength={cartLength}
                        updateCartLength={updateCartLength}
                        userName={userName}
                        setUserName={setUserName}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                      />
                    </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/history"
                  element={
                    <LanguageWrapper>
                      <ReservationHistory
                        isVerified={isVerified}
                        setIsVerified={setIsVerified}
                        userName={userName}
                        setUserName={setUserName}
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                      />
                    </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/detail/:procedureCategory"
                  element={
                    <Detail
                      cartLength={cartLength}
                      updateCartLength={updateCartLength}
                    />
                  }
                />
                <Route
                  path="/:lang/search"
                  element={
                    // <LanguageWrapper>
                    <WholeSearch
                      searchText={searchQuery}
                      setSearchText={setSearchQuery}
                      searchOpen={searchOpen}
                      setSearchOpen={setSearchOpen}
                      lastParams={lastParams}
                    />
                    // </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/policy"
                  element={
                    <LanguageWrapper>
                      <Policy />
                    </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/marketingpolicy"
                  element={
                    <LanguageWrapper>
                      <Marketingpolicy />
                    </LanguageWrapper>
                  }
                />
                <Route
                  path="/:lang/caution"
                  element={
                    <LanguageWrapper>
                      <Caution setTabOpen={setTabOpen} />
                    </LanguageWrapper>
                  }
                />
                <Route path="/:lang/upload" element={<Upload />} />
              </Routes>
            </div>
            {isCouncelPopupOpen && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  width: "100%",
                  height: "100%",
                  maxWidth: "750px",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  zIndex: 300,
                }}
                onClick={() => setIsCouncelPopupOpen(false)}
              >
                <CouncelingMethodPopup
                  onClose={() => setIsCouncelPopupOpen(false)}
                />
              </div>
            )}
            {languageMenuOpen && (
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  width: "100%",
                  height: "100%",
                  maxWidth: "750px",
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                  zIndex: 300,
                }}
                onClick={() => setLanguageMenuOpen(false)}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50px",
                    left: window.innerWidth > 750 ? "10%" : "13%",
                    width: "60px",
                    height: "fit-content",
                    zIndex: 300,
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontWeight: "500",
                      color: "#45474e",
                      fontSize: "0.9rem",
                      textAlign: "center",
                      cursor: "pointer",
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/ko");
                      setLanguageMenuOpen(false);
                    }}
                  >
                    KOR
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontWeight: "500",
                      color: "#45474e",
                      fontSize: "0.9rem",
                      textAlign: "center",
                      cursor: "pointer",
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/en");
                      setLanguageMenuOpen(false);
                    }}
                  >
                    ENG
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontWeight: "500",
                      color: "#45474e",
                      fontSize: "0.9rem",
                      textAlign: "center",
                      cursor: "pointer",
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/jp");
                      setLanguageMenuOpen(false);
                    }}
                  >
                    JPN
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontWeight: "500",
                      color: "#45474e",
                      fontSize: "0.9rem",
                      textAlign: "center",
                      cursor: "pointer",
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/cn");
                      setLanguageMenuOpen(false);
                    }}
                  >
                    CHN
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontWeight: "500",
                      color: "#45474e",
                      fontSize: "0.9rem",
                      textAlign: "center",
                      cursor: "pointer",
                      padding: 1,
                    }}
                    onClick={() => {
                      navigate("/th");
                      setLanguageMenuOpen(false);
                    }}
                  >
                    THA
                  </Typography>
                </div>
              </div>
            )}
          </div>
        </LanguageProvider>
      </div>
    );
}

export default App;
