import { Box, Typography } from "@mui/material";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { getDoc, doc } from "firebase/firestore";
import { storage, firestore } from "../service/firebaseConfig"; // Adjust the import path as needed
import { useState, useEffect } from "react";
import onda from "./images/010_onda.jpg";
import potenza from "./images/015_potenza.jpg";
import porezero from "./images/porezero.jpg";
import { useLanguage } from "../LanguageContext";
import { Link } from "react-router-dom";

type ImageUrl = {
  name: string;
  url: string;
};
type Description = {
  title: string;
  description: string;
  link: string;
};
const HorizontalProcedure: React.FC = () => {
  const [imageUrls, setImageUrls] = useState<ImageUrl[]>([]);
  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  const size = window.innerWidth > 750 ? "300px" : "200px";
  const [descriptions, setDescriptions] = useState<Description[]>([]);
  const description = [
    {
      title: "울쎄라",
      description: "함께 거울보며 디자인 후 시술",
      link: `/${language}/detail/102`,
    },
    {
      title: "목주름패키지",
      description: "나이테 없애는 목주름 시술",
      link: `/${language}/event?category=6`,
    },
    {
      title: "원데이 색소패키지",
      description: "피코토닝 + 엑셀브이 + 모델링팩",
      link: `/${language}/event?category=2`,
    },
    {
      title: "쥬베룩 볼륨",
      description: "프리아의 정교한 쥬베룩 시술",
      link: `/${language}/detail/801`,
    },
    {
      title: "리쥬란",
      description: "한땀한땀 꼼꼼빽빽 리쥬란",
      link: `/${language}/detail/601`,
    },
    {
      title: "원데이 V라인패키지",
      description: "프리아의 디자인 리프팅",
      link: `/${language}/event?category=1`,
    },
  ];

  async function fetchImages(): Promise<ImageUrl[]> {
    const folderRef = ref(storage, "EventProcedure"); // "EventProcedure" 폴더 참조
    const imageUrls: ImageUrl[] = [];

    try {
      // 폴더의 모든 항목 가져오기
      const result = await listAll(folderRef);
      const docRef = doc(firestore, "search", "bannerProcedures");
      const docSnap = await getDoc(docRef);

      // 각 항목의 URL과 이름 가져오기
      for (const itemRef of result.items) {
        const url = await getDownloadURL(itemRef); // 이미지 URL 가져오기
        imageUrls.push({
          name: itemRef.name, // 파일 이름
          url: url, // 다운로드 URL
        });
      }

      if (docSnap.exists()) {
        setDescriptions(docSnap.data().procedures as Description[]);
      }
      return imageUrls;
    } catch (error) {
      console.error("Error fetching images:", error);
      return [];
    }
  }
  useEffect(() => {
    fetchImages().then((imageUrls) => setImageUrls(imageUrls));
  }, []);
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%", // 가로 스크롤 영역의 너비
        height: window.innerWidth > 750 ? "400px" : "300px", // 가로 스크롤 영역의 높이
        overflowX: "auto", // 가로 스크롤 설정
        overflowY: "hidden", // 세로 스크롤 숨김
        whiteSpace: "nowrap", // 자식 요소들이 한 줄로 배치되도록 설정
        padding: 2,
        paddingTop: 0,
        margin: "0 auto", // 가운데 정렬
        scrollbarWidth: "standard", // 스크롤바 숨김
      }}
    >
      <Box
        sx={{
          display: "inline-block", // 자식 요소를 가로로 나열
          width: "fit-content",
          height: "100%",
          textAlign: "center",
          color: "#fff",
          padding: 0,
        }}
      >
        <Link
          to={`/${language}/detail/305`}
          style={{
            textDecoration: "none", // 밑줄 제거
            color: "inherit",
          }}
        >
          <img
            src={porezero}
            alt="GoTo"
            style={{
              width: size,
              height: size,
              display: "block", // Ensures the image is treated as a block element
              margin: "0 auto", // Centers the image horizontally
            }}
          />
          <Typography
            sx={{
              fontSize: "1rem",
              width: size,
              fontFamily: "Pretendard",
            }}
            align="left"
            color="textPrimary"
            fontWeight="600"
            marginLeft="10px"
          >
            포어제로 테라피
          </Typography>
          <Typography
            align="left"
            color="textPrimary"
            marginLeft="10px"
            sx={{
              fontFamily: "Pretendard",
              whiteSpace: "normal",
              fontSize: "0.9rem",
              fontWeight: "400",
              width: size,
            }}
          >
            "화제의 그 모공삭제시술"
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: "inline-block", // 자식 요소를 가로로 나열
          width: "fit-content",
          height: "100%",
          textAlign: "center",
          color: "#fff",
          padding: 0,
        }}
      >
        <Link
          to={`/${language}/detail/101`}
          style={{
            textDecoration: "none", // 밑줄 제거
            color: "inherit",
          }}
        >
          <img
            src={onda}
            alt="GoTo"
            style={{
              width: size,
              height: size,
              display: "block", // Ensures the image is treated as a block element
              margin: "0 auto", // Centers the image horizontally
            }}
          />
          <Typography
            sx={{
              fontSize: "1rem",
              width: size,
              fontFamily: "Pretendard",
            }}
            align="left"
            color="textPrimary"
            fontWeight="600"
            marginLeft="10px"
          >
            온다
          </Typography>
          <Typography
            align="left"
            color="textPrimary"
            marginLeft="10px"
            sx={{
              fontFamily: "Pretendard",
              whiteSpace: "normal",
              fontSize: "0.9rem",
              fontWeight: "400",
              width: size,
            }}
          >
            효과빠른 프리미엄 리프팅
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: "inline-block", // 자식 요소를 가로로 나열
          width: "fit-content",
          height: "100%",
          textAlign: "center",
          color: "#fff",
          padding: 1,
        }}
      >
        <Link
          to={`/${language}/detail/301`}
          style={{
            textDecoration: "none", // 밑줄 제거
            color: "inherit",
          }}
        >
          <img
            src={potenza}
            alt="GoTo"
            style={{
              width: size,
              height: size,
              display: "block", // Ensures the image is treated as a block element
              margin: "0 auto", // Centers the image horizontally
            }}
          />
          <Typography
            sx={{
              fontSize: "1rem",
              width: size,
              fontFamily: "Pretendard",
            }}
            align="left"
            color="textPrimary"
            fontWeight="600"
            marginLeft="10px"
          >
            포텐자
          </Typography>
          <Typography
            align="left"
            color="textPrimary"
            marginLeft="10px"
            sx={{
              fontFamily: "Pretendard",
              whiteSpace: "normal",
              fontSize: "0.9rem",
              fontWeight: "400",
              width: size,
            }}
          >
            프리아의 빈틈없는 포텐자
          </Typography>
        </Link>
      </Box>
      {/* 가로로 스크롤될 항목들 */}
      {imageUrls?.map((item, index) => (
        <Box
          key={index}
          sx={{
            display: "inline-block", // 자식 요소를 가로로 나열
            width: "fit-content",
            height: "100%",
            textAlign: "center",
            // lineHeight: "100px",
            color: "#fff",
            padding: 1,
          }}
        >
          <Link
            to={`/${language}/${descriptions[index].link}`}
            style={{
              textDecoration: "none", // 밑줄 제거
              color: "inherit",
            }}
          >
            <img
              src={item.url}
              key={item.name}
              alt="GoTo"
              style={{
                width: size,
                height: size,
                display: "block", // Ensures the image is treated as a block element
                margin: "0 auto", // Centers the image horizontally
              }}
            />
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: size,
                fontFamily: "Pretendard",
              }}
              align="left"
              color="textPrimary"
              fontWeight="bold"
              marginLeft="10px"
            >
              {descriptions[index].title}
            </Typography>
            <Typography
              align="left"
              color="textPrimary"
              marginLeft="10px"
              sx={{
                fontFamily: "Pretendard",
                whiteSpace: "normal",
                fontSize: "0.9rem",
                fontWeight: "400",
                width: size,
              }}
            >
              {descriptions[index].description}
            </Typography>
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export default HorizontalProcedure;
