import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import SearchModule from "./function_components/SearchModule";
// MUI import
import { Box, Tabs, Tab } from "@mui/material";
import dia_indicator from "./images/dia_indicator.png";
import { useLanguage } from "../LanguageContext";

interface Header3Props {
  lastParams: string;
}

const Header3: React.FC<Header3Props> = ({ lastParams }) => {
  const [tabState, setTabState] = useState<number>(0);
  const [isVisible, setIsVisible] = useState(true);
  const lastScrollY = useRef<number>(0); // 마지막 스크롤 위치 저장
  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState(newValue);
  };

  const locations: string[] = [
    "asdf ",
    `/${language}/event`,
    `/${language}/reservation`,
    `/${language}/introduction`,
  ];

  const locales = {
    ko: {
      home: "홈",
      event: "이벤트",
      reservation: "시술 예약",
      introduction: "소개",
    },
    en: {
      home: "Home",
      event: "Event",
      reservation: "Price",
      introduction: "About us",
    },
    jp: {
      home: "ホーム",
      event: "キャンペーン",
      reservation: "ご予約",
      introduction: "紹介",
    },
    th: {
      home: "หน้าแรก",
      event: "กิจกรรม",
      reservation: "การจอง",
      introduction: "เกี่ยวกับเรา",
    },
    cn: {
      home: "首页",
      event: "活动",
      reservation: "价格",
      introduction: "医院介绍",
    },
  };
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const { home, event, reservation, introduction } = locales[langkey];

  // 스크롤 이벤트 핸들러
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = Math.max(window.scrollY, 0);

      if (currentScrollY > lastScrollY.current) {
        setIsVisible(false); // 스크롤을 아래로 내리면 숨김
      } else {
        setIsVisible(true); // 스크롤을 위로 올리면 표시
      }
      if (currentScrollY === 0) {
        setIsVisible(true);
      }
      lastScrollY.current = currentScrollY;
    };
    // 스크롤 이벤트 리스너 추가
    window.addEventListener("scroll", handleScroll);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: isVisible ? 50 : 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
        height: 40,
        backgroundColor: "rgba(112, 107, 105, 0.48)",
        backdropFilter: "blur(6px) brightness(80%)",
        overflow: "hidden",
        zIndex: 90, // Header1이 Header2보다 위에 있도록 설정
        maxWidth: "750px" /* Match the max-width of the container */,
        pointerEvents: "auto",
        transition: "top 0.3s ease",
      }}
    >
      {/* 상단 탭 위 다이아 인디케이터 */}
      {(lastParams === `/${language}` ||
        locations.some((loc) => lastParams.startsWith(loc))) && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left:
              lastParams === `/${language}` // 홈 탭일 경우
                ? `calc(12.5% - 5px)`
                : `calc(25% * ${locations.findIndex((item) =>
                    lastParams.startsWith(item)
                  )} + 12.5% - 5px)`, // 탭의 25% 간격으로 이동
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "left 0.5s",
          }}
        >
          <img
            src={dia_indicator}
            alt="dia"
            style={{
              width: "10px",
              height: "12px",
            }}
          />
        </Box>
      )}
      {/* 상단 탭 */}
      <Tabs
        value={tabState}
        onChange={handleChange}
        TabIndicatorProps={{ style: { display: "none" } }}
        variant="fullWidth"
        aria-label="Top Tabs"
        sx={{ position: "absolute", width: "100%", height: "40px", py: 0 }}
      >
        <Tab
          label={home}
          sx={{
            fontFamily: "Pretendard",
            fontWeight: "500",
            marginTop: "4px",
            px: "0px",
            color: "#decec2",
            textTransform: "none",
            "&.Mui-selected": {
              color: "#decec2", // 선택된 탭의 텍스트 색상을 흰색으로 유지
            },
            fontSize: "0.9rem",
          }}
          component={Link}
          to={`/${language}`}
        />
        <Tab
          label={event}
          sx={{
            fontFamily: "Pretendard",
            fontWeight: "500",
            marginTop: "4px",
            px: "0px",
            color: "#decec2",
            textTransform: "none",
            "&.Mui-selected": {
              color: "#decec2", // 선택된 탭의 텍스트 색상을 흰색으로 유지
            },
            fontSize: "0.9rem",
          }}
          component={Link}
          to={`/${language}/event`}
        />
        <Tab
          label={reservation}
          sx={{
            fontFamily: "Pretendard",
            fontWeight: "500",
            marginTop: "4px",
            px: "0px",
            color: "#decec2",
            textTransform: "none",
            "&.Mui-selected": {
              color: "#decec2", // 선택된 탭의 텍스트 색상을 흰색으로 유지
            },
            fontSize: "0.9rem",
          }}
          component={Link}
          to={`/${language}/reservation`}
        />
        <Tab
          label={introduction}
          sx={{
            fontFamily: "Pretendard",
            fontWeight: "500",
            marginTop: "4px",
            px: "0px",
            color: "#decec2",
            textTransform: "none",
            "&.Mui-selected": {
              color: "#decec2", // 선택된 탭의 텍스트 색상을 흰색으로 유지
            },
            fontSize: "0.9rem",
          }}
          component={Link}
          to={`/${language}/introduction`}
        />
      </Tabs>
    </Box>
  );
};

export default Header3;
