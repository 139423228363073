import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

interface LanguageContextProps {
  language: string;
  setLanguage: (lang: string) => void;
}

// const findLanguage = async (): Promise<string> => {
//   try {
//     const response = await fetch("https://ipinfo.io/json?token=9fee49490d5de4");
//     if (!response.ok) {
//       throw new Error(`Error fetching location: ${response.statusText}`);
//     }

//     const data: { country?: string } = await response.json();
//     const country = data.country || "KR"; // 기본값: 한국(KR)

//     const redirectPaths: Record<string, string> = {
//       KR: "ko",
//       JP: "jp",
//       CN: "cn",
//       US: "en",
//       TH: "th",
//       default: "en",
//     };
//     return redirectPaths[country] || redirectPaths.default;
//   } catch (error) {
//     console.error("Error fetching location:", error);
//     return "en"; // 기본값: 영어(en)
//   }
// };

const LanguageContext = createContext<LanguageContextProps>({
  language: "en",
  setLanguage: () => {},
});

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation(); // React Router의 useLocation 훅
  const [language, setLanguage] = useState(() => {
    const initialPathParts = location.pathname.split("/");
    return initialPathParts[1] || "en"; // URL에서 언어 추출 또는 기본값 설정
  }); // 기본 언어 설정

  // useEffect(() => {
  //   const initializeLanguage = async () => {
  //     const langFromAPI = await findLanguage(); // 비동기 언어 확인
  //     setLanguage(langFromAPI);
  //   };

  //   initializeLanguage();
  // }, []);

  useEffect(() => {
    const pathParts = location.pathname.split("/"); // URL을 '/' 기준으로 분리
    const langFromURL = pathParts[1]; // 첫 번째 파라미터
    if (langFromURL && langFromURL !== language) {
      setLanguage(langFromURL); // 언어 상태 업데이트
    }
    // console.log("LanguageContext: ", langFromURL);
  }, [location.pathname]); // URL이 바뀔 때마다 실행

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
