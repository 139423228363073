import React from "react";
import ReactDOM from "react-dom";
import { Box, Button, Typography } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import ChatIcon from "@mui/icons-material/Chat";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import kakaotalkIcon from "./images/kakaotalkIcon.jpg";
import phoneIcon from "./images/phoneIcon.png";
import locales from "./text_assets/locale.js";

interface CouncelingMethodPopup {
  onClose: () => void;
}

const CouncelingMethodPopup: React.FC<CouncelingMethodPopup> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  const { language } = useLanguage(); // 현재 언어 상태 가져오기

  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const { consultMethod, byVisit, byCall, bySNS, closer } = locales[langkey];

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        maxWidth: 350,
        height: "40%",
        maxHeight: 240,
        bgcolor: "white",
        borderRadius: 2,
        overflow: "hidden",
        p: 1,
        zIndex: 100,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        gap: 1.5,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Typography
        sx={{
          height: "fit-content",
          color: "black",
          fontWeight: "600",
          textAlign: "center",
          p: 0,
        }}
      >
        {consultMethod}
      </Typography>
      <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            width: "33%",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "50px",
              aspectRatio: "1",
              maxHeight: 100,
              bgcolor: "#d29094ee",
              borderRadius: "50%",
              position: "relative",
              color: "#3f3936",
              fontWeight: "800",
              textAlign: "center",
            }}
            onClick={() => {
              navigate(`${language}/cart/1`);
              onClose();
            }}
          >
            <EventNoteIcon
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "50%",
                color: "black",
              }}
            />
          </Button>
          <Typography
            sx={{
              fontFamily: "Pretendard",
              fontSize: "0.9rem",
              fontWeight: "500",
            }}
          >
            {byVisit}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33%",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "50px",
              aspectRatio: "1",
              maxHeight: 100,
              bgcolor: "#57a959c7",
              borderRadius: "50%",
              position: "relative",
              color: "#3f3936",
              fontWeight: "800",
              textAlign: "center",
            }}
            onClick={() => (window.location.href = "tel:0262293499")}
          >
            <img
              src={phoneIcon}
              alt="phone"
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "50%",
              }}
            />
          </Button>
          <Typography
            sx={{
              fontFamily: "Pretendard",
              fontSize: "0.9rem",
              fontWeight: "500",
            }}
          >
            {byCall}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33%",
            height: "fit-content",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
          onClick={() => window.open("http://pf.kakao.com/_xhxmizn/", "_blank")}
        >
          <img
            src={kakaotalkIcon}
            alt="kakaotalk"
            style={{
              width: "65px",
              aspectRatio: "1",
              cursor: "pointer",
              // height: "auto",
              // borderRadius: "50%",
            }}
          />
          {/* </Button> */}
          <Typography
            sx={{
              fontFamily: "Pretendard",
              fontSize: "0.9rem",
              fontWeight: "500",
            }}
          >
            {bySNS}
          </Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        sx={{
          width: "90%",
          height: "15%",
          maxHeight: 50,
          bgcolor: "#f6f6f2",
          borderRadius: 1,
          // position: "fixed",
          bottom: 10,
          color: "#a7a78c",
          fontWeight: "600",
          textAlign: "center",
        }}
        onClick={onClose}
      >
        {closer}
      </Button>
    </Box>
  );
};

export default CouncelingMethodPopup;
