import React from "react";
import { Box, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from "../LanguageContext";
import locales from "./text_assets/locale.js";

interface CartAddedPopupProps {
  cartLength: number;
}

const CartAddedPopup: React.FC<CartAddedPopupProps> = ({ cartLength }) => {
  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  const navigate = useNavigate();
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const { directReservation } = locales[langkey];
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: window.innerWidth > 750 ? "50px" : "45px",
        backgroundColor: "black",
        // borderRadius: 2,
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1000",
        cursor: "pointer",
        boxShadow: "0px -3px 5px rgba(0, 0, 0, 0.5)",
      }}
      onClick={() => navigate(`/${language}/cart`)}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 16px 0 16px",
          flexGrow: 1,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "'Pretendard-SemiBold', Helvetica",
              fontWeight: "bold",
              color: "#f6f6f2",
              fontSize: window.innerWidth > 750 ? "1.125rem" : "0.95rem",
            }}
          >
            {directReservation}
          </Typography>
        </Box>
        <Box sx={{ marginLeft: 1 }}>
          <Typography
            sx={{
              textDecoration: "none",
              fontFamily: "'Pretendard-Regular', Helvetica",
              fontWeight: "600",
              alignText: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: window.innerWidth > 750 ? "1.125rem" : "0.95rem",
              marginRight: 1,
              borderRadius: "50%",
              backgroundColor: "grey",
              width: window.innerWidth > 750 ? "25px" : "20px",
              aspectRatio: 1,
            }}
          >
            {cartLength}
          </Typography>
        </Box>
      </Box>
    </Box>
    // document.body
  );
};

export default CartAddedPopup;
