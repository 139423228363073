import React, { useState, useEffect, ChangeEvent } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../all_css/Header.css";
import { Box, Typography, TextField, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useLanguage } from "../../LanguageContext";
import { collection, getDoc, getDocs, doc } from "firebase/firestore";
import { firestore } from "../../service/firebaseConfig.js";
import locales from "../text_assets/locale.js";

interface SearchMaterials {
  category: string;
  title: string;
  description: string;
}
interface SearchModuleProps {
  setSearchQuery: (query: string) => void;
  setSearchOpen: (open: boolean) => void;
}

const SearchModule: React.FC<SearchModuleProps> = ({
  setSearchQuery,
  setSearchOpen,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<SearchMaterials[]>([]);
  const navigate = useNavigate();
  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  const [topKeywords, setTopKeywords] = useState<string[]>([]);
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const { searchPlaceholder, keywordAlert } = locales[langkey];

  const topKeywordsFetch = async () => {
    const keywordsNationality =
      language === "ko" ? "keywords" : `keywords_${language}`;
    try {
      const docRef = doc(firestore, "search", keywordsNationality);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setTopKeywords(docSnap.data().keywords);
      }
    } catch (error) {
      console.error("Error fetching searching keywords:", error);
    }
  };

  useEffect(() => {
    topKeywordsFetch();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        mt: 3,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          variant="standard"
          placeholder={searchPlaceholder}
          sx={{
            width: "100%",
            "& .MuiInputBase-input::placeholder": {
              // color: "#cdc5c1", // placeholder 색상 설정
              color: "grey",
              fontWeight: "500",
            },
            "& .MuiInputBase-input": {
              color: "grey", // 실제 입력한 텍스트의 색상 설정
            },
            "& .MuiInput-underline": {
              borderBottom: "2px solid black", // 밑줄 두껍게
            },
            input: {
              fontFamily: "Pretendard",
              fontSize: "1.1rem",
              fontWeight: "500",
            }, // This targets the input styles directly
          }}
          fullWidth
          value={searchText} // Bind the input value to the state
          onChange={handleSearchChange} // Handle input changes
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setSearchQuery(searchText);
              setSearchOpen(true);
              if (searchText.trim())
                navigate(`/${language}/search/?keyword=${searchText}`);
              else alert(`${keywordAlert}`);
            }
          }}
        />
        <IconButton
          sx={{ position: "absolute", right: 10 }}
          onClick={() => {
            setSearchQuery(searchText);
            setSearchOpen(true);
            if (searchText.trim())
              navigate(`/${language}/search/?keyword=${searchText}`);
            else alert(`${keywordAlert}`);
          }}
        >
          <SearchIcon
            sx={{
              fontSize: 30, // 크기 설정 (기본적으로 fontSize로 크기 조절)
              color: "black", // 색상 설정
            }}
          />
        </IconButton>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
        {topKeywords.map((keyword, index) => (
          <Typography
            key={index}
            onClick={() => {
              setSearchQuery(keyword);
              setSearchOpen(true);
              navigate(`/${language}/search/?keyword=${keyword}`);
            }}
            sx={{
              backgroundColor: "grey",
              padding: "3px 7px 3px 7px",
              borderRadius: 1,
              color: "white",
              fontFamily: "Pretendard",
              marginRight: 1,
              cursor: "pointer",
              fontSize: "0.9rem",
            }}
          >
            {keyword}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default SearchModule;
