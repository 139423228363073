import React from "react";
import {
  List,
  ListItem,
  IconButton,
  Typography,
  Box,
  Slide,
} from "@mui/material";
import "./all_css/Event.css";
import CloseIcon from "@mui/icons-material/Close";
import quickBookingCircle from "./images/quickBookingCircle.png";
import bookCheckCircle from "./images/bookCheckCircle.png";
import cartCircle from "./images/cartCircle.png";
import consultIconCircle from "./images/consultIconCircle.png";
import { Link } from "react-router-dom";
import dia_indicator from "./images/dia_indicator.png";
import { useLanguage } from "../LanguageContext";
import instaLogo_empty from "./images/instaLogo_empty.png";
import kakaotalk_yellow from "./images/kakaotalk_yellow.png";
import lineIcon from "./images/lineIcon.jpg";
import locales from "./text_assets/locale.js";

interface sideMenuProps {
  isOpen: boolean;
  onClose: () => void;
  setIsCouncelPopupOpen: (open: boolean) => void;
}

const SideMenu: React.FC<sideMenuProps> = ({
  isOpen,
  onClose,
  setIsCouncelPopupOpen,
}) => {
  const { language } = useLanguage(); // 현재 언어 상태 가져오기
  type localeKey = keyof typeof locales;
  const langkey: localeKey = language as localeKey;
  const {
    quickBooking,
    reservationCheck,
    cart,
    consult,
    priaIntro,
    reservation,
    event,
    Insta,
    SNS,
  } = locales[langkey];

  return isOpen ? (
    <div className="menupopup">
      <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
        <Box
          sx={{
            width: "70%",
            height: "100vh",
            overflow: "hidden",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "6vh",
              backgroundColor: "#E8E8E8",
              position: "relative",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <IconButton
              sx={{
                left: "4%",
                top: "30%",
                width: "32px",
                height: "32px",
                padding: 2,
              }}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "30vh",
              backgroundColor: "#E8E8E8",
              position: "relative",
              top: "-1px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: "20px",
                left: "5%",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "10px",
                  height: "70px",
                }}
              >
                <Link
                  to={`${language}/reservation`}
                  onClick={onClose}
                  style={{
                    textDecoration: "none",
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="img"
                    src={quickBookingCircle}
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      textAlign: "center",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      marginTop: 0,
                      color: "black",
                      whiteSpace: "pre-wrap", // 줄바꿈 허용
                      wordBreak: "break-word", // 단어 단위 줄바꿈
                      width: "100px",
                      overflowWrap: "break-word",
                    }}
                  >
                    {quickBooking}
                  </Typography>
                </Link>
              </Box>
              <Box sx={{ position: "relative", paddingTop: "10px" }}>
                <Link
                  to={`${language}/cart`}
                  onClick={onClose}
                  style={{
                    textDecoration: "none",
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="img"
                    src={cartCircle}
                    style={{ width: "50px", height: "50px" }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      textAlign: "center",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      marginTop: 0,
                      color: "black",
                    }}
                  >
                    {cart}
                  </Typography>
                </Link>
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
                right: "3%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "10px",
                  height: "70px",
                }}
              >
                <Link
                  to={`${language}/history`}
                  onClick={onClose}
                  style={{
                    textDecoration: "none",
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="img"
                    src={bookCheckCircle}
                    style={{ width: "50px", height: "50px" }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      textAlign: "center",
                      fontSize: "0.875rem",
                      fontWeight: "500",
                      marginTop: 0,
                      color: "black",
                      whiteSpace: "pre-wrap", // 줄바꿈 허용
                      wordBreak: "break-word", // 단어 단위 줄바꿈
                      width: "100px",
                      overflowWrap: "break-word",
                    }}
                  >
                    {reservationCheck}
                  </Typography>
                </Link>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  paddingTop: "10px",
                  cursor: "pointer",

                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  setIsCouncelPopupOpen(true);
                  onClose();
                }}
              >
                <img
                  alt="img"
                  src={consultIconCircle}
                  style={{ width: "50px", height: "50px" }}
                />

                <Typography
                  sx={{
                    fontFamily: "Pretendard",
                    textAlign: "center",
                    fontSize: "0.875rem",
                    fontWeight: "500",
                    marginTop: 0,
                    color: "black",
                  }}
                >
                  {consult}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "70vh",
              position: "relative",
              backgroundColor: "#DEDEDE",
              // backdropFilter: "blur(120px) brightness(100%)",
              display: "flex",
              // flexDirection: "column",
              // justifyContent: "flex-start",
              // alignItems: "flex-end",
              top: "-1px",
            }}
          >
            <List
              sx={{
                paddingTop: "30%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ListItem
                sx={{ padding: "0 0 20px 0", justifyContent: "center" }}
              >
                <Link
                  to={`${language}/introduction`}
                  onClick={onClose}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <img
                    src={dia_indicator}
                    alt="dia"
                    style={{
                      width: "10px",
                      height: "12px",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: "1rem",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    {priaIntro}
                  </Typography>
                  <img
                    src={dia_indicator}
                    alt="dia"
                    style={{
                      width: "10px",
                      height: "12px",
                    }}
                  />
                </Link>
              </ListItem>
              <ListItem
                sx={{ padding: "0 0 20px 0", justifyContent: "center" }}
              >
                <Link
                  to={`${language}/reservation`}
                  onClick={onClose}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: "1rem",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    {reservation}
                  </Typography>
                </Link>
              </ListItem>
              <ListItem
                sx={{ padding: "0 0 20px 0", justifyContent: "center" }}
              >
                <Link
                  to={`${language}/event`}
                  onClick={onClose}
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: "1rem",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    {event}
                  </Typography>
                </Link>
              </ListItem>
              <ListItem
                sx={{
                  padding: "100px 0 20px 0",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  gap: "15%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    language === "ko"
                      ? window.open(
                          "https://www.instagram.com/pria_clinic?igsh=N3lvem8xeTI4NzBz",
                          "_blank"
                        )
                      : language === "jp"
                      ? window.open(
                          "https://www.instagram.com/pria_clinic_jp/",
                          "_blank"
                        )
                      : window.open(
                          "https://www.instagram.com/priaclinic.th/",
                          "_blank"
                        );
                  }}
                >
                  <img
                    src={instaLogo_empty}
                    alt="insta"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    {Insta}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    language === "ko"
                      ? window.open("http://pf.kakao.com/_xhxmizn/", "_blank")
                      : language === "jp"
                      ? window.open("https://lin.ee/rLULi2R", "_blank")
                      : window.open("https://lin.ee/JYDSW8S", "_blank");
                  }}
                >
                  <img
                    src={language === "ko" ? kakaotalk_yellow : lineIcon}
                    alt="insta"
                    style={{ width: "25px", height: "25px" }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      color: "black",
                    }}
                  >
                    {SNS}
                  </Typography>
                </Box>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Slide>

      <Box
        sx={{
          width: "30%",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        }}
        onClick={onClose}
      />
    </div>
  ) : (
    <></>
  );
};

export default SideMenu;
